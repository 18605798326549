<template>
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-12">
      <label for="wight"
        ><u
          >ให้ผู้สูงอายุเลือกอาชีพหลักที่ต้องการทำในอนาคตอย่างน้อย 1 อาชีพ</u
        ></label
      >
    </div>
    <div class="p-formgroup-inline p-col-12 p-md-12">
      <div
        class="p-field-checkbox"
        style="align-items: inherit"
        v-for="(value, key) in A5001"
        :key="key"
      >
        <Checkbox :name="key" v-model="dataSet.careerNeed_" :value="key" />
        <label for="city4" class="p-pt-1" v-html="value"></label>
        <div class="p-formgroup-inline" v-if="value === 'ไม่เลือก 7 อาชีพนี้'">
          <div class="p-field p-col-12 p-md-12">
            <label for="wight">โปรดระบุอาชีพหลักที่ต้องการทำในอนาคต</label>
          </div>
          <InputText
            type="text"
            v-model="dataSet.careerNeedOth"
            placeholder="โปรดระบุอาชีพหลักที่ต้องการทำในอนาคต"
            :disabled="isDisables('careerNeed_', 'c8')"
          />
          <div class="p-formgroup-inline p-col-12 p-md-12">
            <label for="wight">เหตุผล เพราะ</label>
            <Textarea
              rows="5"
              v-model="dataSet.careerNeedReason"
              placeholder="เหตุผล เพราะ"
              :disabled="isDisables('careerNeed_', 'c8')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 1.อาชีพค้าขาย -->
  <div
    class="p-fluid p-formgrid p-grid"
    v-if="dataSet.careerNeed_?.includes('c1')"
  >
    <div class="p-field p-col-12 p-md-12">
      <label for="wight"><b>1. อาชีพค้าขาย (สำหรับคนทำค้าขาย)</b></label>
      <DataTable
        :value="dataSet.decisionTableTrade"
        class="p-datatable-striped p-datatable-gridlines p-datatable-responsive"
      >
        <ColumnGroup type="header">
          <Row>
            <Column
              header="โปรดประเมินความสามารถของท่านในปัจจุบัน ในการทำกิจกรรมต่อไปนี้"
              :rowspan="2"
              headerStyle="width:40%;"
            />
            <Column header="ไม่แน่ใจ" headerClass="p-text-center" />
            <Column header="ทำไม่ได้เลย" headerClass="p-text-center" />
            <Column header="ทำได้เล็กน้อย" headerClass="p-text-center" />
            <Column header="ทำได้ ปานกลาง" headerClass="p-text-center" />
            <Column header="ทำได้ดี" headerClass="p-text-center" />
            <Column header="ทำได้ดีมาก" headerClass="p-text-center" />
          </Row>
          <Row>
            <Column header="" headerClass="p-text-center" />
            <Column header="1" headerClass="p-text-center" />
            <Column header="2" headerClass="p-text-center" />
            <Column header="3" headerClass="p-text-center" />
            <Column header="4" headerClass="p-text-center" />
            <Column header="5" headerClass="p-text-center" />
          </Row>
        </ColumnGroup>

        <Column header="Name">
          <template #body="slotProps">
            <span v-html="slotProps.data['topic']"></span>
          </template>
        </Column>

        <!-- option 0-->
        <Column header="ไม่แน่ใจ(0)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="99"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 0-->

        <!-- option 1-->
        <Column header="ทำไม่ได้เลย(1)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="1"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 1-->

        <!-- option 2-->
        <Column header="ทำได้เล็กน้อย(2)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="2"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 2-->

        <!-- option 3-->
        <Column header="ทำได้ ปานกลาง(3)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="3"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 3-->

        <!-- option  4-->
        <Column header="ทำได้ดี(4)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="4"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 4-->

        <!-- option 5-->
        <Column header="ทำได้ดีมาก(5)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="5"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 5-->

      </DataTable>
    </div>
  </div>
  <!-- /1.อาชีพค้าขาย -->

  <!-- 2.อาชีพทำไร่ -->
  <div
    class="p-fluid p-formgrid p-grid"
    v-if="dataSet.careerNeed_?.includes('c2')"
  >
    <div class="p-field p-col-12 p-md-12">
      <label for="wight"><b>2. อาชีพทำไร่ทำสวน</b></label>
      <DataTable
        :value="dataSet.decisionTableFarm"
        class="p-datatable-striped p-datatable-gridlines p-datatable-responsive"
      >
        <ColumnGroup type="header">
          <Row>
            <Column
              header="โปรดประเมินความสามารถของท่านในปัจจุบัน ในการทำกิจกรรมต่อไปนี้"
              :rowspan="2"
              headerStyle="width:40%;"
            />
            <Column header="ไม่แน่ใจ" headerClass="p-text-center" />
            <Column header="ทำไม่ได้เลย" headerClass="p-text-center" />
            <Column header="ทำได้เล็กน้อย" headerClass="p-text-center" />
            <Column header="ทำได้ ปานกลาง" headerClass="p-text-center" />
            <Column header="ทำได้ดี" headerClass="p-text-center" />
            <Column header="ทำได้ดีมาก" headerClass="p-text-center" />
          </Row>
          <Row>
            <Column header="" headerClass="p-text-center" />
            <Column header="1" headerClass="p-text-center" />
            <Column header="2" headerClass="p-text-center" />
            <Column header="3" headerClass="p-text-center" />
            <Column header="4" headerClass="p-text-center" />
            <Column header="5" headerClass="p-text-center" />
          </Row>
        </ColumnGroup>

        <Column header="Name">
          <template #body="slotProps">
            <span v-html="slotProps.data['topic']"></span>
          </template>
        </Column>

        <!-- option 0-->
        <Column header="ไม่แน่ใจ(0)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="99"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 0-->

        <!-- option 1-->
        <Column header="ทำไม่ได้เลย(1)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="1"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 1-->

        <!-- option 2-->
        <Column header="ทำได้เล็กน้อย(2)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="2"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 2-->

        <!-- option 3-->
        <Column header="ทำได้ ปานกลาง(3)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="3"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 3-->

        <!-- option  4-->
        <Column header="ทำได้ดี(4)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="4"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 4-->

        <!-- option 5-->
        <Column header="ทำได้ดีมาก(5)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="5"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 5-->
      </DataTable>
    </div>
  </div>
  <!-- /2.อาชีพทำไร่ -->

  <!-- 3.อาชีพกรีดยาง -->
  <div
    class="p-fluid p-formgrid p-grid"
    v-if="dataSet.careerNeed_?.includes('c3')"
  >
    <div class="p-field p-col-12 p-md-12">
      <label for="wight"><b>3. อาชีพกรีดยาง</b></label>
      <DataTable
        :value="dataSet.decisionTableGardener"
        class="p-datatable-striped p-datatable-gridlines p-datatable-responsive"
      >
        <ColumnGroup type="header">
          <Row>
            <Column
              header="โปรดประเมินความสามารถของท่านในปัจจุบัน ในการทำกิจกรรมต่อไปนี้"
              :rowspan="2"
              headerStyle="width:40%;"
            />
            <Column header="ไม่แน่ใจ" headerClass="p-text-center" />
            <Column header="ทำไม่ได้เลย" headerClass="p-text-center" />
            <Column header="ทำได้เล็กน้อย" headerClass="p-text-center" />
            <Column header="ทำได้ ปานกลาง" headerClass="p-text-center" />
            <Column header="ทำได้ดี" headerClass="p-text-center" />
            <Column header="ทำได้ดีมาก" headerClass="p-text-center" />
          </Row>
          <Row>
            <Column header="" headerClass="p-text-center" />
            <Column header="1" headerClass="p-text-center" />
            <Column header="2" headerClass="p-text-center" />
            <Column header="3" headerClass="p-text-center" />
            <Column header="4" headerClass="p-text-center" />
            <Column header="5" headerClass="p-text-center" />
          </Row>
        </ColumnGroup>

        <Column header="Name">
          <template #body="slotProps">
            <span v-html="slotProps.data['topic']"></span>
          </template>
        </Column>

        <!-- option 0-->
        <Column header="ไม่แน่ใจ(0)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="99"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 0-->

        <!-- option 1-->
        <Column header="ทำไม่ได้เลย(1)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="1"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 1-->

        <!-- option 2-->
        <Column header="ทำได้เล็กน้อย(2)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="2"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 2-->

        <!-- option 3-->
        <Column header="ทำได้ ปานกลาง(3)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="3"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 3-->

        <!-- option  4-->
        <Column header="ทำได้ดี(4)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="4"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 4-->

        <!-- option 5-->
        <Column header="ทำได้ดีมาก(5)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="5"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 5-->
      </DataTable>
    </div>
  </div>
  <!-- /3.อาชีพกรีดยาง -->

  <!-- 4.อาชีพทำสวนผลไม้ -->
  <div
    class="p-fluid p-formgrid p-grid"
    v-if="dataSet.careerNeed_?.includes('c4')"
  >
    <div class="p-field p-col-12 p-md-12">
      <label for="wight"><b>4. อาชีพทำสวนผลไม้</b></label>
      <DataTable
        :value="dataSet.decisionTableFruit"
        class="p-datatable-striped p-datatable-gridlines p-datatable-responsive"
      >
        <ColumnGroup type="header">
          <Row>
            <Column
              header="โปรดประเมินความสามารถของท่านในปัจจุบัน ในการทำกิจกรรมต่อไปนี้"
              :rowspan="2"
              headerStyle="width:40%;"
            />
            <Column header="ไม่แน่ใจ" headerClass="p-text-center" />
            <Column header="ทำไม่ได้เลย" headerClass="p-text-center" />
            <Column header="ทำได้เล็กน้อย" headerClass="p-text-center" />
            <Column header="ทำได้ ปานกลาง" headerClass="p-text-center" />
            <Column header="ทำได้ดี" headerClass="p-text-center" />
            <Column header="ทำได้ดีมาก" headerClass="p-text-center" />
          </Row>
          <Row>
            <Column header="" headerClass="p-text-center" />
            <Column header="1" headerClass="p-text-center" />
            <Column header="2" headerClass="p-text-center" />
            <Column header="3" headerClass="p-text-center" />
            <Column header="4" headerClass="p-text-center" />
            <Column header="5" headerClass="p-text-center" />
          </Row>
        </ColumnGroup>

        <Column header="Name">
          <template #body="slotProps">
            <span v-html="slotProps.data['topic']"></span>
          </template>
        </Column>

        <!-- option 0-->
        <Column header="ไม่แน่ใจ(0)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="99"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 0-->

        <!-- option 1-->
        <Column header="ทำไม่ได้เลย(1)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="1"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 1-->

        <!-- option 2-->
        <Column header="ทำได้เล็กน้อย(2)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="2"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 2-->

        <!-- option 3-->
        <Column header="ทำได้ ปานกลาง(3)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="3"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 3-->

        <!-- option  4-->
        <Column header="ทำได้ดี(4)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="4"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 4-->

        <!-- option 5-->
        <Column header="ทำได้ดีมาก(5)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="5"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 5-->
      </DataTable>
    </div>
  </div>
  <!-- /4.อาชีพทำสวนผลไม้ -->

  <!-- 5.อาชีพทำนา -->
  <div
    class="p-fluid p-formgrid p-grid"
    v-if="dataSet.careerNeed_?.includes('c5')"
  >
    <div class="p-field p-col-12 p-md-12">
      <label for="wight"><b>5. อาชีพทำนา</b></label>
      <DataTable
        :value="dataSet.decisionTableRice"
        class="p-datatable-striped p-datatable-gridlines p-datatable-responsive"
      >
        <ColumnGroup type="header">
          <Row>
            <Column
              header="โปรดประเมินความสามารถของท่านในปัจจุบัน ในการทำกิจกรรมต่อไปนี้"
              :rowspan="2"
              headerStyle="width:40%;"
            />
            <Column header="ไม่แน่ใจ" headerClass="p-text-center" />
            <Column header="ทำไม่ได้เลย" headerClass="p-text-center" />
            <Column header="ทำได้เล็กน้อย" headerClass="p-text-center" />
            <Column header="ทำได้ ปานกลาง" headerClass="p-text-center" />
            <Column header="ทำได้ดี" headerClass="p-text-center" />
            <Column header="ทำได้ดีมาก" headerClass="p-text-center" />
          </Row>
          <Row>
            <Column header="" headerClass="p-text-center" />
            <Column header="1" headerClass="p-text-center" />
            <Column header="2" headerClass="p-text-center" />
            <Column header="3" headerClass="p-text-center" />
            <Column header="4" headerClass="p-text-center" />
            <Column header="5" headerClass="p-text-center" />
          </Row>
        </ColumnGroup>

        <Column header="Name">
          <template #body="slotProps">
            <span v-html="slotProps.data['topic']"></span>
          </template>
        </Column>

        <!-- option 0-->
        <Column header="ไม่แน่ใจ(0)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="99"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 0-->

        <!-- option 1-->
        <Column header="ทำไม่ได้เลย(1)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="1"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 1-->

        <!-- option 2-->
        <Column header="ทำได้เล็กน้อย(2)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="2"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 2-->

        <!-- option 3-->
        <Column header="ทำได้ ปานกลาง(3)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="3"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 3-->

        <!-- option  4-->
        <Column header="ทำได้ดี(4)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="4"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 4-->

        <!-- option 5-->
        <Column header="ทำได้ดีมาก(5)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="5"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 5-->
      </DataTable>
    </div>
  </div>
  <!-- /5.อาชีพทำนา -->

  <!-- 6.อาชีพดูแลเด็กเล็ก -->
  <div
    class="p-fluid p-formgrid p-grid"
    v-if="dataSet.careerNeed_?.includes('c6')"
  >
    <div class="p-field p-col-12 p-md-12">
      <label for="wight"><b>6. อาชีพดูแลเด็กเล็ก 0-3 ขวบ</b></label>
      <DataTable
        :value="dataSet.decisionTableNanny"
        class="p-datatable-striped p-datatable-gridlines p-datatable-responsive"
      >
        <ColumnGroup type="header">
          <Row>
            <Column
              header="โปรดประเมินความสามารถของท่านในปัจจุบัน ในการทำกิจกรรมต่อไปนี้"
              :rowspan="2"
              headerStyle="width:40%;"
            />
            <Column header="ไม่แน่ใจ" headerClass="p-text-center" />
            <Column header="ทำไม่ได้เลย" headerClass="p-text-center" />
            <Column header="ทำได้เล็กน้อย" headerClass="p-text-center" />
            <Column header="ทำได้ ปานกลาง" headerClass="p-text-center" />
            <Column header="ทำได้ดี" headerClass="p-text-center" />
            <Column header="ทำได้ดีมาก" headerClass="p-text-center" />
          </Row>
          <Row>
            <Column header="" headerClass="p-text-center" />
            <Column header="1" headerClass="p-text-center" />
            <Column header="2" headerClass="p-text-center" />
            <Column header="3" headerClass="p-text-center" />
            <Column header="4" headerClass="p-text-center" />
            <Column header="5" headerClass="p-text-center" />
          </Row>
        </ColumnGroup>

        <Column header="Name">
          <template #body="slotProps">
            <span v-html="slotProps.data['topic']"></span>
          </template>
        </Column>

        <!-- option 0-->
        <Column header="ไม่แน่ใจ(0)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="99"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 0-->

        <!-- option 1-->
        <Column header="ทำไม่ได้เลย(1)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="1"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 1-->

        <!-- option 2-->
        <Column header="ทำได้เล็กน้อย(2)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="2"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 2-->

        <!-- option 3-->
        <Column header="ทำได้ ปานกลาง(3)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="3"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 3-->

        <!-- option  4-->
        <Column header="ทำได้ดี(4)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="4"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 4-->

        <!-- option 5-->
        <Column header="ทำได้ดีมาก(5)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="5"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 5-->

      </DataTable>
    </div>
  </div>
  <!-- /6.อาชีพดูแลเด็กเล็ก -->

  <!-- 7.	อาชีพแนะนำขายผลิตภัณฑ์ในร้านค้าและในระบบออนไลน์ -->
  <div
    class="p-fluid p-formgrid p-grid"
    v-if="dataSet.careerNeed_?.includes('c7')"
  >
    <div class="p-field p-col-12 p-md-12">
      <label for="wight"><b>7. อาชีพแนะนำขายผลิตภัณฑ์ในร้านค้าและในระบบออนไลน์</b></label>
      <DataTable
        :value="dataSet.decisionTableSalepromotion"
        class="p-datatable-striped p-datatable-gridlines p-datatable-responsive"
      >
        <ColumnGroup type="header">
          <Row>
            <Column
              header="โปรดประเมินความสามารถของท่านในปัจจุบัน ในการทำกิจกรรมต่อไปนี้"
              :rowspan="2"
              headerStyle="width:40%;"
            />
            <Column header="ไม่แน่ใจ" headerClass="p-text-center" />
            <Column header="ทำไม่ได้เลย" headerClass="p-text-center" />
            <Column header="ทำได้เล็กน้อย" headerClass="p-text-center" />
            <Column header="ทำได้ ปานกลาง" headerClass="p-text-center" />
            <Column header="ทำได้ดี" headerClass="p-text-center" />
            <Column header="ทำได้ดีมาก" headerClass="p-text-center" />
          </Row>
          <Row>
            <Column header="" headerClass="p-text-center" />
            <Column header="1" headerClass="p-text-center" />
            <Column header="2" headerClass="p-text-center" />
            <Column header="3" headerClass="p-text-center" />
            <Column header="4" headerClass="p-text-center" />
            <Column header="5" headerClass="p-text-center" />
          </Row>
        </ColumnGroup>

        <Column header="Name">
          <template #body="slotProps">
            <span v-html="slotProps.data['topic']"></span>
          </template>
        </Column>

        <!-- option 0-->
        <Column header="ไม่แน่ใจ(0)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="99"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 0-->

        <!-- option 1-->
        <Column header="ทำไม่ได้เลย(1)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="1"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 1-->

        <!-- option 2-->
        <Column header="ทำได้เล็กน้อย(2)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="2"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 2-->

        <!-- option 3-->
        <Column header="ทำได้ ปานกลาง(3)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="3"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 3-->

        <!-- option  4-->
        <Column header="ทำได้ดี(4)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="4"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 4-->

        <!-- option 5-->
        <Column header="ทำได้ดีมาก(5)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="5"
              v-model="slotProps.data['select']"
            />
          </template>
        </Column>
        <!-- /option 5-->

      </DataTable>
    </div>
  </div>
  <!-- /7.อาชีพแนะนำขายผลิตภัณฑ์ในร้านค้าและในระบบออนไลน์ -->
</template>
<script>
export default {
  name: 'section5',
  data() {
    return {
      A5001: {
        c1: '1. อาชีพค้าขาย (ข้ามไปที่หน้า 18)',
        c2: '2. อาชีพทำไร่/ทำสวน (ข้ามไปที่หน้า 19)',
        c3: '3. อาชีพกรีดยาง (ข้ามไปที่หน้า 20)',
        c4: '4. อาชีพทำสวนผลไม้ (ข้ามไปที่หน้า 21)',
        c5: '5. อาชีพทำนา (ข้ามไปที่หน้า 22)',
        c6: '6. อาชีพรับดูแลเด็กเล็ก 0-3 ขวบ (ข้ามไปที่หน้า 23)',
        c7: '7. อาชีพแนะนำขายผลิตภัณฑ์ในร้านค้าและในระบบออนไลน์ (ข้ามไปที่หน้า 24)',
        c8: 'ไม่เลือก 7 อาชีพนี้',
        c99: 'ปฏิเสธให้ข้อมูล'
      }
    }
  },
  watch: {
    'dataSet.careerNeed_': {
      handler(newV, oldV) {
        this.$store.dispatch('entry/handlerRefuseFieldsCareerNeed', {
          new: newV,
          old: oldV,
          name: 'careerNeed_'
        })
      },
      deep: true
    },
  },
  computed: {
    dataSet() {
      return this.$store.state.entry.entity
    }
  },
  methods: {
    getDataSetEntry() {
      //alert(JSON.stringify(this.dataSet));
      const { decisionTableTrade } = this.dataSet
      const decisionValues = decisionTableTrade.reduce((values, radio) => {
        const { name, select } = radio || {}
        //console.log("values ::==", values);
        //console.log("select ::==", select);
        //console.log("name ::==", name);
        return (values = { ...values, [name]: select })
      }, {})
      alert('decisionValues ::==' + JSON.stringify(decisionValues))

      const payload = { ...this.dataSet, decisionValues }
      //console.log("payload ::== " + JSON.stringify(payload));
      // เอา payload ส่งไป save
    },
    isDisables(name, value) {
      return !(this.dataSet[name] == null ? [] : this.dataSet[name]).includes(
        value
      );
    }
  },
  hasValueThenVisible(value) {
    return this.dataSet.careerNeed_?.includes(value)
  }
}
</script>