<template>
  <div class="p-fluid p-formgrid p-grid right">
    <h4>
      แบบสำรวจเลขที่
      <u>{{
        dataSet.mappingCode ? dataSet.mappingCode : 'ไม่ระบุ แบบเลขที่สำรวจ'
      }}</u>
    </h4>
  </div>
  <div class="p-fluid p-formgrid p-grid">
    <h4>ชื่อผู้สำรวจ</h4>
    <div class="p-field p-col-12 p-sm-8">
      <InputText
        type="text"
        v-model="dataSet.surveyName"
        placeholder="ชื่อผู้สำรวจ"
      />
    </div>
  </div>
  <div class="p-fluid p-formgrid p-grid">
    <h4>วันที่ทำการสำรวจ</h4>
    <!-- birthdate -->
    <div class="p-field p-col-12 p-md-2">
      <Dropdown
        id="bdDay"
        v-model="dataSurveyDate.svDay"
        :options="bdDate.days"
        optionLabel="text"
        placeholder="เลือกวันที่"
        optionValue="value"
      />
    </div>
    <div class="p-field p-col-12 p-md-3">
      <Dropdown
        v-model="dataSurveyDate.svMonth"
        :options="bdDate.months"
        optionLabel="text"
        placeholder="เลือกเดือน"
        optionValue="value"
      />
    </div>
    <div class="p-field p-col-12 p-md-2">
      <Dropdown
        v-model="dataSurveyDate.svYear"
        :options="bdDate.years"
        optionLabel="text"
        placeholder="เลือกปี"
        optionValue="value"
      />
    </div>
    <!-- /birthdate -->
  </div>
  <div class="p-fluid p-formgrid p-grid">
    <h4>เวลา</h4>
    <div class="p-formgroup-inline p-col-11">
      <h4>เริ่ม</h4>
      <div class="p-fluid p-col-4 p-sm-2">
        <InputText
          type="text"
          v-model="dataSet.surveyStartTime"
          placeholder="เริ่ม"
        />
      </div>
      <div class="p-fluid">
        <h4>ถึง</h4>
      </div>
      <div class="p-fluid p-col-4 p-sm-2">
        <InputText
          type="text"
          v-model="dataSet.surveyEndTime"
          placeholder="ถึง"
        />
      </div>
    </div>
  </div>
  <div class="p-fluid p-formgrid p-grid">
    <h6><RadioButton /> เลือกได้เพียง 1 คำตอบ</h6>
  </div>
  <div class="p-fluid p-formgrid p-grid">
    <h6><Checkbox /> เลือกได้มากกว่า 1 คำตอบ</h6>
  </div>
</template>

<script>
export default {
  name: 'sectionHead',
  data() {
    return {}
  },
  computed: {
    dataSet() {
      return this.$store.state.entry.entity
    },
    bdDate() {
      const [now, ago] = this.$store.state.constance.years
      return {
        ...this.$store.state.constance,
        years: [now, ago]
      }
    },
    dataSurveyDate() {
      return this.$store.state.entry.surveyDate
    }
  }
}
</script>

<style>
.right {
  padding-left: 50%;
}
</style>