<template>
  <label for="wight"><u>รายได้และรายจ่าย</u></label>

  <!-- 1. รายได้ครอบครัว  -->
  <div class="p-fluid p-formgrid p-grid">
    <div
      class="p-formgroup-inline p-col-12 p-md-fixed p-sm-2"
      style="width: 250px"
    >
      <label for="wight">1. <b>รายได้ครอบครัว</b> เฉลี่ยรวมต่อเดือน</label>
    </div>
    <div class="p-field p-col-12 p-md-2">
      <InputNumber
        type="text"
        v-model="dataSet.incomeTotal"
        mode="decimal"
        :minFractionDigits="2"
        :maxFractionDigits="2"
        placeholder="บาท"
      />
    </div>
    <div class="p-field p-col-12 p-md-5">
      <label for="wight">โดยมีรายละเอียด ดังนี้</label>
    </div>
    <div class="p-formgroup-inline p-col-12 p-md-12">
      <div
        class="p-field-checkbox"
        style="align-items: inherit"
        v-for="(value, key) in A2001"
        :key="key"
      >
        <Checkbox :name="key" v-model="dataSet.incomeSource_" :value="key" />
        <label for="city4" class="p-pt-1">{{ value }}</label>
        <div v-if="value === 'อื่นๆ ระบุ'">
          <InputText
            type="text"
            v-model="dataSet.incomeOth"
            placeholder="อื่น ๆ"
            :disabled="isDisables('incomeSource_', 'c9')"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- /1. รายได้ครอบครัว -->

  <!-- 2. รายจ่ายครอบครัว เฉลี่ยรวมต่อเดือน  -->
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-12">
      <label for="wight">2. <b>รายจ่ายครอบครัว</b> เฉลี่ยรวมต่อเดือน</label>
    </div>
    <div class="p-field p-col-12 p-md-3">
      <InputNumber
        type="text"
        v-model="dataSet.expendAvg"
        mode="decimal"
        :minFractionDigits="2"
        :maxFractionDigits="2"
        placeholder="บาท"
      />
    </div>
    <RadioButton value="expendAvgFake" v-model="dataSet.expendAvgFake" />
    <label for="wight">&nbsp;ปฏิเสธให้ข้อมูล</label>
  </div>
  <!-- 2. รายจ่ายครอบครัว เฉลี่ยรวมต่อเดือน -->

  <!-- 3. รายจ่ายเพื่อสุขภาพ  -->
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-12">
      <label for="wight"
        >3. ในช่วง 12 เดือนที่ผ่านมา รายจ่ายเพื่อสุขภาพ
        <b>(ไม่รวมค่าเดินทาง ไม่รวมประกันสุขภาพ)</b></label
      >
    </div>
    <div class="p-field p-col-12 p-md-12">
      <div class="p-field p-col-12 p-md-6">
        <label for="wight"
          >มีค่ายาจำนวนประมาณ</label
        >
      </div>
      <div class="p-field p-col-12 p-md-6">
        <InputNumber
          type="text"
          v-model="dataSet.expendMedicine"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="2"
          placeholder="บาท/ปี"
        />
      </div>
    </div>
    <div class="p-field p-col-12 p-md-12">
      <div class="p-field p-col-12 p-md-6">
        <label for="wight"
          >มีค่ารักษาพยาบาลผู้ป่วยนอกจำนวนประมาณ</label
        >
      </div>
      <div class="p-field p-col-12 p-md-6">
        <InputNumber
          type="text"
          v-model="dataSet.expendOPD"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="2"
          placeholder="บาท/ปี"
        />
      </div>
    </div>
    <div class="p-field p-col-12 p-md-12">
      <div class="p-field p-col-12 p-md-6">
        <label for="wight"
          >มีค่ารักษาพยาบาลผู้ป่วยในจำนวนประมาณ</label
        >
      </div>
      <div class="p-field p-col-12 p-md-6">
        <InputNumber
          type="text"
          v-model="dataSet.expendIPD"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="2"
          placeholder="บาท/ปี"
        />
      </div>
      <RadioButton
        value="expendMedicineFake"
        v-model="dataSet.expendMedicineFake"
      />
      <label for="wight">&nbsp;ปฏิเสธให้ข้อมูล</label>
    </div>
    
  </div>
  <!-- 3. รายจ่ายเพื่อสุขภาพ -->

  <!-- 4.  ภายในครัวเรือนของท่าน รายได้เพียงพอกับค่าใช้จ่ายหรือไม่ -->
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-sm-12">
      <label for="wight"
        >4. ภายใน<b>ครัวเรือน</b>ของท่าน รายได้เพียงพอกับค่าใช้จ่ายหรือไม่
      </label>
      <div class="p-formgroup-inline">
        <div class="p-field-checkbox" v-for="(value, key) in A2004" :key="key">
          <RadioButton
            name="marital"
            v-model="dataSet.incomeEnough"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>
    </div>
  </div>
  <!-- /4.  ภายในครัวเรือนของท่าน รายได้เพียงพอกับค่าใช้จ่ายหรือไม่ -->

  <!-- 5. ปัจจุบันท่านประกอบอาชีพหรือไม่ -->
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-sm-12">
      <label for="wight"
        >5. ปัจจุบันท่านประกอบอาชีพหรือไม่
        <b>(ประกอบอาชีพ คือ ต้องก่อให้เกิดรายได้)</b></label
      >
      <div class="p-formgroup-inline">
        <div class="p-field-checkbox" v-for="(value, key) in A2005" :key="key">
          <RadioButton
            name="marital"
            v-model="dataSet.careerFlag"
            :value="key"
          />
          <label for="city4" v-html="value"></label>
        </div>
      </div>
    </div>
  </div>
  <!-- /5. ปัจจุบันท่านประกอบอาชีพหรือไม่่ -->

  <label for="wight" v-if="dataSet.careerFlag === 'c1'"
    ><b><u>กรณีปัจจุบันได้ประกอบอาชีพ</u></b> (ก่อให้เกิดรายได้: เงิน, สิ่งของ/
    ทำข้อ 6-13)</label
  >

  <!-- 6. อาชีพปัจจุบันทำอะไรบ้าง (ทั้งอาชีพหลักและอาชีพรอง)  -->
  <div class="p-fluid p-formgrid p-grid" v-if="dataSet.careerFlag === 'c1'">
    <div class="p-field p-col-12 p-md-12">
      <label for="wight"
        >6. อาชีพปัจจุบันทำอะไรบ้าง <b>(ทั้งอาชีพหลักและอาชีพรอง)</b></label
      >
    </div>
    <div class="p-formgroup-inline p-col-12 p-md-12">
      <div
        class="p-field-checkbox"
        style="align-items: inherit"
        v-for="(value, key) in A2006"
        :key="key"
      >
        <Checkbox :name="key" v-model="dataSet.careerCurrent_" :value="key" />
        <label for="city4" class="p-pt-1">{{ value }}</label>
        <div v-if="value === 'อื่นๆ ระบุ'">
          <InputText
            type="text"
            v-model="dataSet.careerCurrentOth"
            placeholder="อื่น ๆ"
            :disabled="isDisables('careerCurrent_', 'c10')"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- /6. อาชีพปัจจุบันทำอะไรบ้าง (ทั้งอาชีพหลักและอาชีพรอง) -->

  <!-- 7. หากมีรายได้จากการทำงาน รายได้หลักจากการทำงานของท่าน เฉลี่ยรวมต่อเดือนที่ผ่านมา ทั้งสิ้น -->
  <div class="p-fluid p-formgrid p-grid" v-if="dataSet.careerFlag === 'c1'">
    <div class="p-field p-col-12 p-sm-12">
      <label for="wight"
        >7. หากมีรายได้จากการทำงาน <b>รายได้หลัก</b>จากการทำงานของท่าน
        เฉลี่ยรวมต่อเดือนที่ผ่านมา ทั้งสิ้น
      </label>
      <div class="p-formgroup-inline p-col-12 p-md-12">
        <div class="p-field">
          <label for="wight">7.1 ทั้งสิ้น</label>
        </div>
        <div class="p-field p-col-12 p-sm-6">
          <InputNumber
            type="text"
            v-model="dataSet.incomeMainTotal"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            placeholder="บาท/เดือน"
          />
        </div>
        <RadioButton
          name="incomeMainTotalFake"
          value="incomeMainTotalFake"
          v-model="dataSet.incomeMainTotalFake"
        />
        <label for="wight">&nbsp;ปฏิเสธให้ข้อมูล</label>
      </div>
      <div class="p-formgroup-inline p-col-12 p-md-12">
        <div class="p-field">
          <label for="wight"
            >7.2 <b><u>ใน 1 เดือน</u></b> คิดเป็น</label
          >
        </div>
        <div class="p-field p-col-12 p-sm-2">
          <InputNumber
            type="text"
            v-model="dataSet.incomeAmt"
            placeholder="จำนวน"
          />
        </div>
        <div
          class="p-field-checkbox"
          style="align-items: inherit"
          v-for="(value, key) in A20072"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="dataSet.incomeDetail"
            :value="key"
          />
          <label for="city4" class="p-pt-1">{{ value }}</label>
          <div v-if="value === 'อื่นๆ ระบุ'">
            <InputText
              type="text"
              v-model="dataSet.incomeDetailOth"
              placeholder="อื่นๆ"
              :disabled="isDisables('incomeDetail', 'c6')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /7. หากมีรายได้จากการทำงาน รายได้หลักจากการทำงานของท่าน เฉลี่ยรวมต่อเดือนที่ผ่านมา ทั้งสิ้น -->

  <!-- 8. ในช่วง 1 สัปดาห์ที่ผ่านมา ทำงานสัปดาห์ละ  -->
  <div class="p-fluid p-formgrid p-grid" v-if="dataSet.careerFlag === 'c1'">
    <div class="p-field p-col-12 p-md-12">
      <label for="wight">8. ในช่วง 1 สัปดาห์ที่ผ่านมา <b>ทำงานสัปดาห์ละ</b></label>
    </div>
    <div class="p-field p-col-12 p-md-1">
      <InputNumber
        type="text"
        v-model="dataSet.workWeekHr"
        placeholder="ชั่วโมง"
      />
    </div>
    <RadioButton
      name="workWeekHrFake"
      value="workWeekHrFake"
      v-model="dataSet.workWeekHrFake"
    />
    <label for="wight">&nbsp;ปฏิเสธให้ข้อมูล</label>
  </div>
  <!-- /8. ในช่วง 1 สัปดาห์ที่ผ่านมา ทำงานสัปดาห์ละ -->

  <!-- 9. ระยะทางและระยะเวลา จากที่อยู่อาศัยปัจจุบันและสถานที่ทำงาน  -->
  <div class="p-fluid p-formgrid p-grid" v-if="dataSet.careerFlag === 'c1'">
    <div class="p-field p-col-12 p-md-12">
      <label for="wight"
        >9. ระยะทางและระยะเวลา จาก<b
          >ที่อยู่อาศัยปัจจุบันและสถานที่ทำงานหลักหรือประจำ</b
        ></label
      >
      <div class="p-formgroup-inline p-col-12">
        <div class="p-field">
          <label for="wight">9.1 ระยะทางประมาณ</label>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <InputNumber
            type="text"
            v-model="dataSet.distantKm"
            placeholder="กิโลเมตร"
          />
        </div>
        <div class="p-field p-col-12 p-md-2">
          <InputNumber
            type="text"
            v-model="dataSet.distantM"
            placeholder="เมตร"
          />
        </div>
        <RadioButton
          name="distantKmFake"
          value="distantKmFake"
          v-model="dataSet.distantKmFake"
        />
        <label for="wight">&nbsp;ปฏิเสธให้ข้อมูล</label>
      </div>
      <div class="p-formgroup-inline p-col-12">
        <div class="p-field">
          <label for="wight">9.2 ระยะเวลาประมาณ</label>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <InputNumber
            type="text"
            v-model="dataSet.durationHr"
            placeholder="ชั่วโมง"
          />
        </div>
        <div class="p-field p-col-12 p-md-2">
          <InputNumber
            type="text"
            v-model="dataSet.durationMin"
            placeholder="นาที"
          />
        </div>
        <RadioButton
          name="durationHrFake"
          value="durationHrFake"
          v-model="dataSet.durationHrFake"
        />
        <label for="wight">&nbsp;ปฏิเสธให้ข้อมูล</label>
      </div>
      <div class="p-field p-col-12 p-md-12">
        <div class="p-field-checkbox" v-for="(value, key) in A2009" :key="key">
          <RadioButton :name="key" v-model="dataSet.workPlace" :value="key" />
          <label for="city4" v-html="value"></label>
        </div>
      </div>
    </div>
  </div>
  <!-- /9. ระยะทางและระยะเวลา จากที่อยู่อาศัยปัจจุบันและสถานที่ทำงาน -->

  <!-- 10. ท่านเดินทางจากที่อยู่อาศัยปัจจุบันและสถานที่ทำงานอย่างไร  -->
  <div
    class="p-fluid p-formgrid p-grid"
    v-if="dataSet.careerFlag === 'c1' && dataSet.workPlace === 'c1'"
  >
    <div class="p-field p-col-12 p-md-12">
      <label for="wight"
        >10. ท่านเดินทางจากที่อยู่อาศัยปัจจุบันและสถานที่ทำงานอย่างไร
        <b>(กรณีที่ทำงานหลายแห่ง เลือกสถานที่ทำงานหลักหรือประจำ)</b></label
      >
    </div>
    <div class="p-formgroup-inline p-col-12 p-md-12">
      <div
        class="p-field-checkbox"
        style="align-items: inherit"
        v-for="(value, key) in A2010"
        :key="key"
      >
        <Checkbox :name="key" v-model="dataSet.workTravelType_" :value="key" />
        <label for="city4" class="p-pt-1">{{ value }}</label>
        <div v-if="value === 'อื่นๆ ระบุ'">
          <InputText
            type="text"
            v-model="dataSet.workTravelOth"
            placeholder="อื่น ๆ"
            :disabled="isDisables('workTravelType_', 'c9')"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- /10. ท่านเดินทางจากที่อยู่อาศัยปัจจุบันและสถานที่ทำงานอย่างไร -->

  <!-- 11.ในช่วง 12 เดือนที่ผ่านมา เคยได้รับบาดเจ็บ/อุบัติเหตุ จากการทำงาน หรือไม่ อย่างไร -->
  <div class="p-fluid p-formgrid p-grid" v-if="dataSet.careerFlag === 'c1'">
    <div class="p-field p-col-12 p-sm-9">
      <label for="wight"
        >11. ในช่วง 12 เดือนที่ผ่านมา
        <b>เคยได้รับบาดเจ็บ/อุบัติเหตุ จากการทำงาน</b>
        หรือไม่ อย่างไร
      </label>
      <div
        class="p-field-checkbox"
        style="align-items: inherit"
        v-for="(value, key) in A2011"
        :key="key"
      >
        <RadioButton name="marital" v-model="dataSet.accident" :value="key" />
        <label for="city4" class="p-pt-1" v-html="value"></label>
        <div
          v-if="
            value ===
            'เคย โปรดระบุ<b>ประเภทบาดเจ็บและอวัยวะที่บาดเจ็บ</b> เช่น มีดบาดนิ้ว'
          "
        >
          <InputText
            type="text"
            v-model="dataSet.accidentDetail"
            placeholder="เคย โปรดระบุ"
            :disabled="isDisables('accident', 'c2')"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- /11.ในช่วง 12 เดือนที่ผ่านมา เคยได้รับบาดเจ็บ/อุบัติเหตุ จากการทำงาน หรือไม่ อย่างไร -->

  <!-- 12. ต้องการจะทำงานเพิ่มหรือไม่ -->
  <div class="p-fluid p-formgrid p-grid" v-if="dataSet.careerFlag === 'c1'">
    <div class="p-field p-col-12 p-sm-12">
      <label for="wight">12. ต้องการจะทำงานเพิ่มหรือไม่ </label>
      <div class="p-field p-col-12 p-md-12">
        <div
          class="p-field-checkbox"
          style="align-items: inherit"
          v-for="(value, key) in A2012"
          :key="key"
        >
          <RadioButton name="marital" v-model="dataSet.needJob" :value="key" />
          <label for="city4" class="p-pt-1" v-html="value"></label>
          <div v-if="value === 'ต้องการทำงานเพิ่ม สามารถทำงานเพิ่มได้อีก'">
            <InputNumber
              type="text"
              v-model="dataSet.needJobHr"
              placeholder="ชั่วโมงต่อวัน"
              :disabled="isDisables('needJob', 'c2')"
            />
          </div>
          <div v-if="value === 'ต้องการทำงานเพิ่ม สามารถทำงานเพิ่มได้อีก'">
            <label for="city4"
              ><b>(ทำข้อ 12.1, 12.2 และข้ามไปทำส่วนที่ 3)</b></label
            >
          </div>
        </div>
      </div>
      <div class="p-field p-col-12 p-sm-12" v-if="dataSet.needJob === 'c2'">
        <div class="p-field p-col-12 p-sm-12">
          <label for="bmi"
            >12.1. ค่าตอบแทนที่คาดหวังกี่บาทต่อชั่วโมง จำนวน</label
          >
        </div>
        <div class="p-field p-col-12 p-md-12">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A20121"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="dataSet.wagesFlag"
              :value="key"
            />
            <label for="city4">{{ value }}</label>
            <div v-if="value === 'ระบุ'">
              <InputNumber
                type="text"
                v-model="dataSet.wagesExpect"
                mode="decimal"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                placeholder="บาท"
                :disabled="isDisables('wagesFlag', 'c1')"
              />
            </div>
          </div>
        </div>
        <div class="p-formgroup-inline p-col-12">
          <label for="bmi">12.2. ลักษณะประเภทที่เหมาะสมกับตนเอง (ระบุ)</label>
        </div>
        <div class="p-field p-col-12 p-sm-9">
          <InputText
            type="text"
            v-model="dataSet.jobExpect"
            placeholder="ลักษณะงานที่เหมาะสมกับตนเอง"
          />
          <RadioButton
            name="jobExpectFake"
            value="jobExpectFake"
            v-model="dataSet.jobExpectFake"
          />
          <label for="wight">&nbsp;ปฏิเสธให้ข้อมูล</label>
        </div>
      </div>
    </div>
  </div>
  <!-- /12. ต้องการจะทำงานเพิ่มหรือไม่ -->

  <!-- 13. สาเหตุที่ไม่ทำงานเพิ่มคือ  -->
  <div
    class="p-fluid p-formgrid p-grid"
    v-if="dataSet.careerFlag === 'c1' && dataSet.needJob === 'c1'"
  >
    <div class="p-field p-col-12 p-md-12">
      <label for="wight">13. สาเหตุที่ไม่ต้องการทำงานเพิ่ม คือ</label>
    </div>
    <div class="p-formgroup-inline p-col-12 p-md-12">
      <div
        class="p-field-checkbox"
        style="align-items: inherit"
        v-for="(value, key) in A2013"
        :key="key"
      >
        <Checkbox :name="key" v-model="dataSet.noNeedJobReason_" :value="key" />
        <label for="city4" class="p-pt-1">{{ value }}</label>
        <div v-if="value === 'อื่นๆ ระบุ'">
          <InputText
            type="text"
            v-model="dataSet.noNeedJobOth"
            placeholder="อื่น ๆ"
            :disabled="isDisables('noNeedJobReason_', 'c6')"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- /13. สาเหตุที่ไม่ทำงานเพิ่มคือ -->

  <label for="wight" v-if="dataSet.careerFlag === 'c2'"
    ><b><u>กรณีที่ปัจจุบันไม่ได้ประกอบอาชีพ</u> (ทำข้อ 14-20)</b></label
  >

  <!-- 14. กรณีที่ต้องการทำงาน ต้องการทำงานเพื่อ  -->
  <div class="p-fluid p-formgrid p-grid" v-if="dataSet.careerFlag === 'c2'">
    <div class="p-field p-col-12 p-md-12">
      <label for="wight"
        >14. กรณีที่ต้องการทำงาน ต้องการทำงานเพื่อ (ตอบได้มากกว่า 1 ข้อ)</label
      >
    </div>
    <div class="p-field p-col-12 p-md-12">
      <div
        class="p-field-checkbox"
        style="align-items: inherit"
        v-for="(value, key) in A2014"
        :key="key"
      >
        <Checkbox :name="key" v-model="dataSet.workFor_" :value="key" />
        <label for="city4" class="p-pt-1">{{ value }}</label>
        <div v-if="value === 'อื่นๆ ระบุ'">
          <InputText
            type="text"
            v-model="dataSet.workForOth"
            placeholder="อื่น ๆ"
            :disabled="isDisables('workFor_', 'c4')"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- /14. กรณีที่ต้องการทำงาน ต้องการทำงานเพื่อ -->

  <!-- 15. กรณีต้องการทำงานเป็นอาชีพ ต้องการทำงานในกลุ่มอาชีพใด  -->
  <div class="p-fluid p-formgrid p-grid" v-if="dataSet.careerFlag === 'c2'">
    <div class="p-field p-col-12 p-md-12">
      <label for="wight"
        >15. กรณีต้องการทำงานเป็นอาชีพ ต้องการทำงานในกลุ่มอาชีพใด</label
      >
    </div>
    <div class="p-formgroup-inline p-col-12 p-md-12">
      <div
        class="p-field-checkbox"
        style="align-items: inherit"
        v-for="(value, key) in A2015"
        :key="key"
      >
        <Checkbox
          :name="key"
          v-model="dataSet.expectCareerGroup_"
          :value="key"
        />
        <label for="city4" class="p-pt-1">{{ value }}</label>
        <div v-if="value === 'อื่นๆ ระบุ'">
          <InputText
            type="text"
            v-model="dataSet.expectCareerOth"
            placeholder="อื่น ๆ"
            :disabled="isDisables('expectCareerGroup_', 'c7')"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- /15. กรณีต้องการทำงานเป็นอาชีพ ต้องการทำงานในกลุ่มอาชีพใด -->

  <!-- 16. หากกำลังหางานทำอยู่ ชั่วโมงการทำงานที่ต้องการต่อวัน -->
  <div class="p-fluid p-formgrid p-grid" v-if="dataSet.careerFlag === 'c2'">
    <div class="p-field p-col-12 p-sm-12">
      <label for="wight"
        >16. หากกำลังหางานทำ ชั่วโมงการทำงานที่ต้องการต่อวัน
      </label>
    </div>
    <div class="p-field p-col-12 p-sm-3">
      <InputNumber
        type="text"
        v-model="dataSet.expectJobHr"
        placeholder="ชั่วโมง"
      />
    </div>
    <RadioButton
      name="expectJobHrFake"
      value="expectJobHrFake"
      v-model="dataSet.expectJobHrFake"
    />
    <label for="wight">&nbsp;ปฏิเสธให้ข้อมูล</label>
    <div class="p-field p-col-12 p-sm-12">
      <label for="wight">ค่าตอบแทนที่คาดหวัง กี่บาทต่อ 1 ชั่วโมง</label>
      <div class="p-field-checkbox" v-for="(value, key) in A2016" :key="key">
        <RadioButton
          name="marital"
          v-model="dataSet.expectWagesHrType"
          :value="key"
        />
        <label for="city4">{{ value }}</label>
        <div v-if="value === 'ระบุ จำนวน'">
          <InputNumber
            type="text"
            v-model="dataSet.expectWagesHr"
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            placeholder="บาท"
            :disabled="isDisables('expectWagesHrType', 'c1')"
          />
        </div>
      </div>
    </div>
    <div class="p-field p-col-12 p-sm-12">
      <div class="p-field p-col-12 p-sm-12">
        <label for="bmi">16.1. ลักษณะประเภทงานที่เหมาะสมกับตนเอง (ระบุ)</label>
      </div>
      <div class="p-field p-col-12 p-sm-8">
        <InputText
          type="text"
          v-model="dataSet.specWork"
          placeholder="ลักษณะงานที่เหมาะสมกับตนเอง"
        />
        <RadioButton
          name="specWorkFake"
          value="specWorkFake"
          v-model="dataSet.specWorkFake"
        />
        <label for="wight">&nbsp;ปฏิเสธให้ข้อมูล</label>
      </div>
      <div class="p-field p-col-12 p-sm-12">
        <label for="bmi"
          >16.2. สุขภาพของท่าน เอื้ออำนวยให้สามารถทำงานที่เลือกในข้อ 16.1
          ได้หรือไม่</label
        >
        <div
          class="p-field-checkbox"
          style="align-items: inherit"
          v-for="(value, key) in A20162"
          :key="key"
        >
          <RadioButton name="marital" v-model="dataSet.canWork" :value="key" />
          <label for="city4" class="p-pt-1">{{ value }}</label>
          <div
            class="p-field p-col-12 p-sm-8"
            v-if="value === 'ไม่ได้/ทำงานได้ไม่เต็มประสิทธิภาพ เพราะ'"
          >
            <InputText
              type="text"
              v-model="dataSet.canWorkOth"
              placeholder="ไม่ได้/ทำงานได้ไม่เต็มประสิทธิภาพ เพราะ"
              :disabled="isDisables('canWork', 'c2')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /16. หากกำลังหางานทำอยู่ ชั่วโมงการทำงานที่ต้องการต่อวัน -->

  <!-- 17. สถานที่ที่ต้องการทำงาน  -->
  <div class="p-fluid p-formgrid p-grid" v-if="dataSet.careerFlag === 'c2'">
    <div class="p-field p-col-12 p-md-12">
      <label for="wight">17. สถานที่ที่ต้องการทำงาน</label>
    </div>
    <div class="p-formgroup-inline p-col-12 p-md-12">
      <div class="p-field-checkbox" v-for="(value, key) in A2017" :key="key">
        <Checkbox :name="key" v-model="dataSet.workLocation_" :value="key" />
        <label for="city4">{{ value }}</label>
      </div>
    </div>
  </div>
  <!-- /17. สถานที่ที่ต้องการทำงาน -->

  <!-- 18. ถ้าต้องการทำงานนอกสถานที่ สามารถเดินทางไปทำงานด้วยตนเองได้หรือไม่ -->
  <div class="p-fluid p-formgrid p-grid" v-if="dataSet.careerFlag === 'c2'">
    <div class="p-field p-col-12 p-sm-8">
      <label for="wight"
        >18. ถ้าต้องการทำงานนอกสถานที่ สามารถเดินทางไปทำงานด้วยตนเองได้หรือไม่
      </label>
      <div class="p-field-checkbox" v-for="(value, key) in A2018" :key="key">
        <RadioButton
          name="marital"
          v-model="dataSet.canTravelSelf"
          :value="key"
        />
        <label for="city4">{{ value }}</label>
        <div v-if="value === 'ไม่ได้ เพราะ'">
          <InputText
            type="text"
            v-model="dataSet.canTravelSelfOth"
            placeholder="ไม่ได้ เพราะ"
            :disabled="isDisables('canTravelSelf', 'c2')"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- /18. ถ้าต้องการทำงานนอกสถานที่ สามารถเดินทางไปทำงานด้วยตนเองได้หรือไม่ -->

  <!-- 19. ระบุความสามารถในการทำงาน -->
  <div class="p-fluid p-formgrid p-grid" v-if="dataSet.careerFlag === 'c2'">
    <div class="p-field p-col-12 p-sm-12">
      <label for="wight">19. ระบุความสามารถในการทำงาน </label>
    </div>
    <div class="p-field p-col-12 p-sm-12">
      <div class="p-field p-col-12 p-sm-12">
        <label for="wight">19.1 ทำงานตอนกลางวัน </label>
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A20191"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="dataSet.workDaytime"
              :value="key"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>
      </div>
      <div class="p-field p-col-12 p-sm-12">
        <label for="wight">19.2 ทำงานตอนกลางคืน </label>
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A20192"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="dataSet.workNight"
              :value="key"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>
      </div>

      <div class="p-field p-col-12 p-sm-12">
        <label for="wight"
          >19.3 สามารถเคลื่อนไหวร่างกายโดยการเดินหรือวิ่ง (เป็นระยะทาง 1
          กม.)</label
        >
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A20193"
            :key="key"
          >
            <RadioButton name="marital" v-model="dataSet.canRun" :value="key" />
            <label for="city4">{{ value }}</label>
          </div>
        </div>
      </div>

      <div class="p-field p-col-12 p-sm-12">
        <label for="wight">19.4 สามารถใช้งานแขนซ้ายและขวาได้ถนัด </label>
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A20194"
            :key="key"
          >
            <RadioButton name="marital" v-model="dataSet.canArm" :value="key" />
            <label for="city4">{{ value }}</label>
          </div>
        </div>
      </div>

      <div class="p-field p-col-12 p-sm-12">
        <label for="wight">19.5 สามารถก้มหรือย่อตัว </label>
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A20195"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="dataSet.canShrink"
              :value="key"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>
      </div>

      <div class="p-field p-col-12 p-sm-12">
        <label for="wight"
          >19.6 สามารถการมองเห็นชัดเจนหรือใช้แว่นสายตาแล้วยังมีการมองเห็นชัดเจน
        </label>
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A20196"
            :key="key"
          >
            <RadioButton name="marital" v-model="dataSet.canSee" :value="key" />
            <label for="city4">{{ value }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /19. ระบุความสามารถในการทำงาน -->

  <!-- 20. ความสามารถด้านภูมิปัญญาท้องถิ่น  -->
  <div class="p-fluid p-formgrid p-grid" v-if="dataSet.careerFlag === 'c2'">
    <div class="p-field p-col-12 p-md-12">
      <label for="wight"
        >20. ความสามารถ<b
          >ด้านภูมิปัญญาท้องถิ่น หรือคลังปัญญาผู้สูงอายุ
          หรือทักษะพิเศษที่เป็นประโยชน์กับการทำงาน</b
        >
        หรือที่ถ่ายทอดให้คนอื่นได้</label
      >
    </div>
    <div class="p-formgroup-inline p-col-12 p-md-12">
      <div
        class="p-field-checkbox"
        style="align-items: inherit"
        v-for="(value, key) in A2020"
        :key="key"
      >
        <Checkbox :name="key" v-model="dataSet.skillSpecial_" :value="key" />
        <label for="city4" class="p-pt-1">{{ value }}</label>
        <div v-if="value === 'อื่นๆ'">
          <InputText
            type="text"
            v-model="dataSet.skillSpecialOth"
            placeholder="อื่น ๆ"
            :disabled="isDisables('skillSpecial_', 'c7')"
          />
        </div>
      </div>
    </div>
    <div class="p-field p-col-12 p-sm-4">
      <label for="bmi">โปรดระบุรายละเอียด</label>
      <Textarea
        rows="5"
        v-model="dataSet.skillSpecialDetail"
        placeholder="โปรดระบุรายละเอียด"
      />
    </div>
  </div>
  <!-- /20. ความสามารถด้านภูมิปัญญาท้องถิ่น -->
</template>
<script>
export default {
  name: 'section2',
  data() {
    return {
      A2006: {
        c1: 'ข้าราชการเกษียณ',
        c2: 'พนักงานบริษัทเอกชน',
        c3: 'เจ้าของกิจการ',
        c4: 'ค้าขาย/ประกอบธุรกิจส่วนตัวที่ไม่ใช่การเกษตร',
        c5: 'รับจ้างเป็นแม่บ้าน/พ่อบ้าน',
        c6: 'รับจ้างทั่วไป/โรงงาน',
        c7: 'เกษตรกร',
        c8: 'ขับรถรับจ้าง (มอเตอร์ไซด์รับจ้าง รถตู้ แท็กซี่)',
        c9: 'กรรมกร',
        c10: 'อื่นๆ ระบุ',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A2009: {
        c1: 'ทำงานนอกสถานที่',
        c2: 'ทำงานที่บ้าน <b>(ให้ข้ามไปข้อ 11)</b>'
      },
      A2010: {
        c1: 'รถยนต์ส่วนตัว (ขับเอง)',
        c2: 'รถยนต์ส่วนตัว (มีคนขับให้)',
        c3: 'รถยนต์รับจ้าง',
        c4: 'รถมอเตอร์ไซค์ส่วนตัว (ขับเอง)',
        c5: 'รถมอเตอร์ไซค์ส่วนตัว (มีคนขับให้)',
        c6: 'รถมอเตอร์ไซค์รับจ้าง',
        c7: 'จักรยาน',
        c8: 'เดินเท้า',
        c9: 'อื่นๆ ระบุ',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A2011: {
        c1: 'ไม่เคย',
        c2:
          'เคย โปรดระบุ<b>ประเภทบาดเจ็บและอวัยวะที่บาดเจ็บ</b> เช่น มีดบาดนิ้ว',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A2012: {
        c1: 'ไม่ต้องการ <b>(ทำข้อ 13 และข้ามไปทำส่วนที่ 3)</b>',
        c2: 'ต้องการทำงานเพิ่ม สามารถทำงานเพิ่มได้อีก',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A20121: {
        c1: 'ระบุ',
        c2: 'ไม่ระบุ',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A2013: {
        c1: 'ขาดเครื่องจักรอุปกรณ์ในการปฏิบัติงาน หรือมีแต่เสียใช้การไม่ได้',
        c2: 'เชื่อว่าตนเองหางานทำเพิ่มไม่ได้',
        c3: 'เจ็บป่วย ปัญหาสุขภาพ',
        c4: 'ดินฟ้าอากาศไม่อำนวย',
        c5: 'ขาดเงินทุน/แหล่งเงินกู้',
        c6: 'อื่นๆ ระบุ',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A2001: {
        c1: 'เบี้ยยังชีพผู้สูงอายุ',
        c2: 'เบี้ยผู้พิการ',
        c3: 'จากการทำงานในปัจจุบัน',
        c4: 'บำเหน็จ/บำนาญ',
        c5: 'ดอกเบี้ยเงินฝากธนาคาร',
        c6: 'จากบุตร/สมาชิกในครอบครัว',
        c7: 'ค่าเช่าที่ดินหรือบ้าน',
        c8: 'บัตรสวัสดิการแห่งรัฐ ',
        c9: 'อื่นๆ ระบุ',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A2004: {
        c1: 'ไม่เพียงพอ (รายจ่ายมากกว่ารายรับ)',
        c2: 'พอกินพอใช้ไม่มีเหลือเก็บ',
        c3: 'มีเงินเหลือเก็บ',
        c4: 'ไม่ทราบ',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A2005: {
        c1: 'ประกอบอาชีพ <b>(ทำข้อ 6-13)</b>',
        c2: 'ไม่ได้ประกอบอาชีพ <b>(ข้ามไปทำข้อ 14-20)</b>',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A2014: {
        c1: 'ต้องการทำงานเป็นอาชีพเพื่อให้มีรายได้ เลี้ยงตนเองและครอบครัว',
        c2: 'ต้องการทำงานเพื่อคลายเหงา/งานอดิเรก/เกิดประโยชน์ต่อสังคม',
        c3: 'ต้องการทำงานด้านจิตอาสา หรือการรวมกลุ่มกิจกรรมเพื่อสุขภาพ',
        c4: 'อื่นๆ ระบุ',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A2016: {
        c1: 'ระบุ จำนวน',
        c2: 'ไม่ระบุ/ไม่ทราบ',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A2015: {
        c1: 'ทำเกษตรตามฤดูกาล (เช่น ปลูกข้าว ปลูกผัก)',
        c2: 'กรีดยาง',
        c3: 'ค้าขาย',
        c4: 'ปศุสัตว์ (สัตว์เศรษฐกิจที่นำมาเลี้ยง เพื่อผลประโยชน์อย่างใดอย่างหนึ่งหรือหลายอย่าง เช่น เพื่อใช้งาน และเป็นอาหาร ฯลฯ)',
        c5: 'ประมง',
        c6: 'รับจ้างทั่วไป',
        c7: 'อื่นๆ ระบุ',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A20162: {
        c1: 'ได้',
        c2: 'ไม่ได้/ทำงานได้ไม่เต็มประสิทธิภาพ เพราะ',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A20072: {
        c1: 'ชิ้น',
        c2: 'ชั่วโมง',
        c3: 'วัน',
        c4: 'สัปดาห์',
        c5: 'เดือน',
        c6: 'อื่นๆ ระบุ',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A2017: {
        c1: 'ทำงานที่บ้าน',
        c2: 'ทำงานนอกสถานที่ได้',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A2018: {
        c1: 'ได้',
        c2: 'ไม่ได้ เพราะ',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A20191: {
        c1: 'ได้',
        c2: 'ไม่ได้',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A20192: {
        c1: 'ได้',
        c2: 'ไม่ได้',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A20193: {
        c1: 'ได้',
        c2: 'ไม่ได้',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A20194: {
        c1: 'ได้',
        c2: 'ไม่ได้',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A20195: {
        c1: 'ได้',
        c2: 'ไม่ได้',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A20196: {
        c1: 'ชัดเจน',
        c2: 'ไม่ชัดเจน',
        c3: 'มองไม่เห็น',
        c4: 'มีอาการตาบอดสี',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A2020: {
        c1: 'ด้านประกอบอาหาร',
        c2: 'ด้านสุขภาพ',
        c3: 'ด้านเกษตร',
        c4: 'ด้านทำงานฝีมือ ประดิษฐ์',
        c5: 'ความรู้วิชาการ',
        c6: 'จิตอาสาต่างๆ',
        c7: 'อื่นๆ',
        c99: 'ปฏิเสธให้ข้อมูล'
      }
    }
  },
  watch: {
    'dataSet.incomeSource_': {
      handler(newV, oldV) {
        this.$store.dispatch('entry/handlerRefuseFields', {
          new: newV,
          old: oldV,
          name: 'incomeSource_'
        })
      },
      deep: true
    },
    'dataSet.careerCurrent_': {
      handler(newV, oldV) {
        this.$store.dispatch('entry/handlerRefuseFields', {
          new: newV,
          old: oldV,
          name: 'careerCurrent_'
        })
      },
      deep: true
    },
    'dataSet.workTravelType_': {
      handler(newV, oldV) {
        this.$store.dispatch('entry/handlerRefuseFields', {
          new: newV,
          old: oldV,
          name: 'workTravelType_'
        })
      },
      deep: true
    },
    'dataSet.noNeedJobReason_': {
      handler(newV, oldV) {
        this.$store.dispatch('entry/handlerRefuseFields', {
          new: newV,
          old: oldV,
          name: 'noNeedJobReason_'
        })
      },
      deep: true
    },
    'dataSet.workFor_': {
      handler(newV, oldV) {
        this.$store.dispatch('entry/handlerRefuseFields', {
          new: newV,
          old: oldV,
          name: 'workFor_'
        })
      },
      deep: true
    },
    'dataSet.expectCareerGroup_': {
      handler(newV, oldV) {
        this.$store.dispatch('entry/handlerRefuseFields', {
          new: newV,
          old: oldV,
          name: 'expectCareerGroup_'
        })
      },
      deep: true
    },
    'dataSet.workLocation_': {
      handler(newV, oldV) {
        this.$store.dispatch('entry/handlerRefuseFields', {
          new: newV,
          old: oldV,
          name: 'workLocation_'
        })
      },
      deep: true
    },
    'dataSet.skillSpecial_': {
      handler(newV, oldV) {
        this.$store.dispatch('entry/handlerRefuseFields', {
          new: newV,
          old: oldV,
          name: 'skillSpecial_'
        })
      },
      deep: true
    }
  },
  computed: {
    dataSet() {
      return this.$store.state.entry.entity
    }
  },
  mounted() {},
  methods: {
    isDisables(name, value) {
      return !(this.dataSet[name] == null ? [] : this.dataSet[name]).includes(
        value
      )
    }
  }
}
</script>