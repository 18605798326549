<template>
  <div id="elder-entry">
    <form @submit.prevent="saveElder">
      <Fieldset legend="แบบสำรวจข้อมูลสุขภาพและความต้องการทำงานของผู้สูงอายุ">
        <div class="p-d-flex p-jc-between">
          <div>
            <Button
              label="ย้อนกลับ"
              icon="pi pi-arrow-left"
              class="p-button-sm p-button-warning"
              @click="() => $router.back(-1)"
            />
          </div>
        </div>

        <SectionHead />
        <Accordion :multiple="true" :activeIndex="[0, 1, 2, 3, 4, 5]">
          <AccordionTab header="ส่วนที่ 1 ข้อมูลส่วนบุคคล">
            <SectionProfile />
            <Section1 />
          </AccordionTab>
          <AccordionTab header="ส่วนที่ 2 ข้อมูลด้านอาชีพ">
            <Section2 />
          </AccordionTab>
          <AccordionTab header="ส่วนที่ 3 ข้อมูลการคัดกรองภาวะสุขภาพ">
            <Section3 />
          </AccordionTab>
          <AccordionTab header="ส่วนที่ 4 ความสามารถในการทำงาน">
            <Section4 />
          </AccordionTab>
          <AccordionTab header="ส่วนที่ 5 ความสามารถในการทำงานเฉพาะอาชีพ">
            <Section5 />
          </AccordionTab>
        </Accordion>
        <div class="p-d-flex p-jc-center">
          <Button
            label="บันทึกข้อมูลเข้าระบบ"
            type="submit"
            icon="pi pi-save"
            class="p-button-success"
          />
          <Button
            label="ยกเลิก"
            type="button"
            icon="pi pi-arrow-left"
            class="p-button-link"
            @click="() => $router.back(-1)"
          />
        </div>
      </Fieldset>
    </form>
  </div>
</template>
<script>
//import CalendarBuddhist from '../components/CalendarBuddhist'
import SectionHead from '@/components/SectionHead'
import SectionProfile from '@/components/SectionProfile'
import Section1 from '@/components/Section1'
import Section2 from '@/components/Section2'
import Section3 from '@/components/Section3'
import Section4 from '@/components/Section4'
import Section5 from '@/components/Section5'

import * as moment from 'moment'

export default {
  name: 'formentry2',
  layout: 'Private',
  data() {
    return {
      // elderly: {},
    }
  },
  computed: {
    /*dataSet() {
      return this.$store.state.entry.entity
    }*/
  },
  async created() {
    /*this.$store.subscribe((mutation, state) => {
      // console.log('mutation ::=', mutation)
      //console.log('state ::=', state)
      //console.log('mutation.type ::=', mutation.type)
      if (mutation.type === 'entry/setEntity') {
        console.log(`Updating to ${state.status}`)
        //this.dataSet = mutation.payload
      } else if (mutation.type === 'entry/setEntryFieldByKey') {
        console.log(`Updating to ${state.status}`)
        //this.dataSet['surveyName'] = mutation.payload?.surveyName
      }
    })*/
    this.$store.commit('constance/initialDate', { primevue: this.$primevue })
    // save activity log
    const { codeId: userId, userLevel, area } = this.$store.getters[
      'oauth/session'
    ]
    await this.$store.dispatch('activity/saveActionActivityLog', {
      actMenu: 'SURVEY'
    })
  },
  async mounted() {
    await this.fetchData()
    // set radio refuse fake
    const fakes = [
      'expendAvg',
      'expendMedicine',
      'expendOPD',
      'expendIPD',
      'incomeMainTotal',
      'workWeekHr',
      { distantKm: { pair: 'distantM', vmodel: 'distantKm' } },
      { distantM: { pair: 'distantKm', vmodel: 'distantKm' } },
      { durationHr: { pair: 'durationMin', vmodel: 'durationHr' } },
      { durationMin: { pair: 'durationHr', vmodel: 'durationHr' } },
      'jobExpect',
      'expectJobHr',
      'specWork',
      'fallTimes'
    ]
    const entry = this.$store.state.entry.entity
    const isSurveyed = entry.createDtm != null
    console.log('isSurveyed ::==', isSurveyed)
    fakes.forEach((name) => {
      //console.log('name ::==', name)
      this.$watch(
        () => {
          let _name = typeof name == 'string' ? name : Object.keys(name)[0]
          return entry[_name]
        },
        (newV, oldV) => {
          // console.log('newV ::==', newV)
          // console.log('oldV ::==', oldV)
          //console.log('name ::==', name)
          let _name
          let _value
          if (typeof name == 'string') {
            _value = name + 'UnFake'
            if ((newV == null || (newV == '' && newV != '0')) && isSurveyed) {
              _value = name + 'Fake'
            }
            _name = name
          } else {
            const _name1 = Object.keys(name)[0]
            //console.log('_name1  ::==', _name1)
            const { pair, vmodel } = name[_name1]
            //console.log('pair, vmodel ::==', pair, vmodel)
            _value = vmodel + 'UnFake'
            const _value2 = entry[pair]
            if (
              (newV == null || (newV == '' && newV != '0')) &&
              isSurveyed &&
              (_value2 == null || (_value2 == '' && _value2 != '0')) &&
              isSurveyed
            ) {
              _value = vmodel + 'Fake'
            }
            _name = vmodel
          }
          //console.log('_value ::==', _value)
          //console.log('_name ::==', _name)
          if(name == 'expendOPD' || name == 'expendIPD'){
              _name = 'expendMedicine';
          }
          this.$store.commit('entry/setEntryFieldByKey', {
            [_name + 'Fake']: _value
          })
        },
        {
          immediate: true
        }
      )
    })
  },
  methods: {
    async fetchData() {
      const { id } = this.$route.params
      let entry = {}
      if (id) {
        const { status, message, data } = await this.$store.dispatch(
          'elderly/fetchElderById',
          id
        )
        entry = data
      }
      if (!entry?.surveyName) {
        const { fname, lname } = this.$store.getters['oauth/session']
        entry['surveyName'] = `${fname}   ${lname}`
      }
      await this.$store.commit('entry/setEntity', {
        ...entry
      })
    },
    async saveElder() {
      const { dateFormatGet, dateFormatSave } = this.$primevue.config.locale
      const entity = this.$store.state.entry.entity

      const { codeId: userId, userLevel: level } = this.$store.getters[
        'oauth/session'
      ]

      const {
        eDisease,
        eBdBcStr,
        medical,
        medical_,
        incomeAmt,
        decisionTablePain,
        decisionTableAbility,
        decisionTableFarm,
        decisionTableFruit,
        decisionTableGardener,
        decisionTableRice,
        decisionTableNanny,
        decisionTableSalepromotion,
        decisionTableTrade,
        surveyName
      } = entity
      //console.log("medical ::== " + medical);
      //console.log("medical_ ::== " + medical_);
      //console.log("incomeAmt ::==", incomeAmt);
      console.log('decisionTablePain ::==' + JSON.stringify(decisionTablePain))
      console.log(
        'transformRadios ::==' + this.transformRadios(decisionTablePain)
      )
      const payload = {
        ...entity,
        ...this.transformRadios(decisionTablePain),
        ...this.transformRadios(decisionTableAbility),
        ...this.transformRadios(decisionTableFarm),
        ...this.transformRadios(decisionTableFruit),
        ...this.transformRadios(decisionTableGardener),
        ...this.transformRadios(decisionTableRice),
        ...this.transformRadios(decisionTableNanny),
        ...this.transformRadios(decisionTableSalepromotion),
        ...this.transformRadios(decisionTableTrade),
        birthday: this.transformBirthDay(),
        surveyDate: this.transformSurveyDate(),
        createBy: userId,
        createDtm: moment().format(dateFormatSave),
        surveyBy: surveyName
      }

      delete payload['decisionTablePain']
      delete payload['decisionTableAbility']
      delete payload['decisionTableFarm']
      delete payload['decisionTableFruit']
      delete payload['decisionTableGardener']
      delete payload['decisionTableRice']
      delete payload['decisionTableNanny']
      delete payload['decisionTableSalepromotion']
      delete payload['decisionTableTrade']

      const excludes = [
        { source: 'maritalStatus', pair: 'maritalStatusOth', value: 'c5' },
        { source: 'medical_', pair: 'medicalOth', value: 'c8' },
        { source: 'houseBelong', pair: 'houseBelongOth', value: 'c4' },
        { source: 'helpOrg_', pair: 'helpOrgOth', value: 'c7' },
        { source: 'incomeSource_', pair: 'incomeOth', value: 'c9' },
        { source: 'incomeDetail', pair: 'incomeDetailOth', value: 'c6' },
        { source: 'workTravelType_', pair: 'workTravelOth', value: 'c8' },
        { source: 'noNeedJobReason_', pair: 'noNeedJobOth', value: 'c6' },
        { source: 'workFor_', pair: 'workForOth', value: 'c4' },
        { source: 'expectCareerGroup_', pair: 'expectCareerOth', value: 'c6' },
        { source: 'canWork', pair: 'canWorkOth', value: 'c2' },
        { source: 'canTravelSelf', pair: 'canTravelSelfOth', value: 'c2' },
        { source: 'skillSpecial_', pair: 'skillSpecialOth', value: 'c7' },
        { source: 'diseaseDetail_', pair: 'diseaseOth', value: 'c12' },
        { source: 'careerNeed_', pair: 'careerNeedOth', value: 'c8' },
        { source: 'helpOrg_', pair: 'helpSkill', value: 'c1' },
        { source: 'helpOrg_', pair: 'helpMedical', value: 'c2' },
        { source: 'accident', pair: 'accidentDetail', value: 'c2' },
        { source: 'needJob', pair: 'needJobHr', value: 'c2' },
        { source: 'wagesFlag', pair: 'wagesExpect', value: 'c1' },
        { source: 'expectWagesHrType', pair: 'expectWagesHr', value: 'c1' },
        { source: 'careerNeed_', pair: 'careerNeedReason', value: 'c8' }
      ]

      excludes.forEach(({ source, pair, value }) => {
        console.log('typeof entity[source] ::==', typeof entity[source])
        const column = entity[source]
        if (
          (typeof column == 'string' && column != value) ||
          (typeof column == 'object' && !column?.includes(value))
        ) {
          delete payload[pair]
        }
      })

      console.log('payload ::==' + JSON.stringify(payload, null, 2))

      //confirm before  save
      this.$confirm.require({
        ...this.$primevue.config.locale,
        reject: async () => {
          try {
            const {
              status,
              code,
              invalidCode,
              data
            } = await this.$store.dispatch('elderly/saveElder', payload)
            console.log('status ::== ', status)
            console.log('data ::== ', data)
            if (200 == code) {
              this.$toast.add({
                severity: 'success',
                summary: 'สถานะการบันทึกข้อมูล',
                detail: 'บันทึกข้อมูลเข้าระบบเรียบร้อย',
                life: 3000
              })
              setTimeout(() => {
                this.$router.push({ path: '/elder/list' })
              }, 1000)
            } else {
              this.$toast.add({
                severity: 'error',
                summary: 'สถานะการทำรายการ',
                detail: 'ระบบไม่สามารถทำรายการได้ ติดต่อเจ้าหน้าที่',
                life: 3000
              })
            }
          } catch (error) {
            const { message: msg } = error
            console.log('login error ::==', error)
            let message = 'ระบบไม่สามารถให้บริการได้ ขออภัยในความไม่สะดวก'
            this.$toast.add({
              severity: 'error',
              summary: 'เกิดข้อผิดพลาดจากระบบ',
              detail: message,
              life: 3000
            })
          }
        }
      })
    },
    transformSurveyDate() {
      const { svDay, svMonth, svYear } = this.$store.state.entry.surveyDate
      console.log('svDay ::==' + svDay + '   svDay::== ' + typeof svDay)
      console.log('svMonth ::==' + svMonth + '   svMonth::== ' + typeof svMonth)
      //console.log("bdYear ::==" + bdYear + "   bdYear::== " + typeof bdYear);

      if (svDay != null && svMonth != null && svYear != null) {
        const _bdYear = moment(svYear, 'YYYY')
          .subtract(543, 'years')
          .format('YYYY')
        return [svDay, svMonth, _bdYear].join('/')
      } else {
        return null
      }
    },
    transformBirthDay() {
      const { bdDay, bdMonth, bdYear } = this.$store.state.entry.birthday
      console.log('bdDay ::==' + bdDay + '   bdDay::== ' + typeof bdDay)
      console.log('bdMonth ::==' + bdMonth + '   bdMonth::== ' + typeof bdMonth)
      //console.log("bdYear ::==" + bdYear + "   bdYear::== " + typeof bdYear);

      if (bdDay != null && bdMonth != null && bdYear != null) {
        const _bdYear = moment(bdYear, 'YYYY')
          .subtract(543, 'years')
          .format('YYYY')
        return [bdDay, bdMonth, _bdYear].join('/')
      } else {
        return null
      }
    },
    transformRadios(radios) {
      // [{"name":"painNeck","topic":"1. คอ","select":null},{"name":"painShoulder","topic":"2.ไหล่","select":2} ]
      // { painNeck : 1 , painShoulder : 2}
      const fields = (radios || []).reduce((result, radio) => {
        const { name, topic, select } = radio
        return (result = { ...result, [name]: select })
      }, {})
      console.log('fields ::==' + JSON.stringify(fields))
      return fields
    }
  },
  components: {
    SectionHead: SectionHead,
    SectionProfile: SectionProfile,
    Section1: Section1,
    Section2: Section2,
    Section3: Section3,
    Section4: Section4,
    Section5: Section5
  }
}
</script>
<style lang="scss" scoped>
:deep(.p-datatable-table) {
  thead tr th,
  tbody tr td:first-child {
    font-size: 14px;
  }
  tbody tr td:first-child {
    font-weight: 400;
  }
}
</style>