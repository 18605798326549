<template>
  <div class="p-fluid p-formgrid p-grid">
    <!-- gender -->
    <div class="p-field p-col-12 p-md-3">
      <div class="p-formgroup-inline">
        <div class="p-field-checkbox p-md-1">
          <label>เพศ</label>
        </div>
        <div class="p-field-checkbox p-md-3">
          <RadioButton
            id="male"
            name="male"
            value="M"
            v-model="dataSet.gender"
          />
          <label for="city3">ชาย</label>
        </div>
        <div class="p-field-checkbox p-md-3">
          <RadioButton
            id="female"
            name="female"
            value="F"
            v-model="dataSet.gender"
          />
          <label for="city4">หญิง</label>
        </div>
      </div>
    </div>
    <!--/ gender -->

    <!-- birthdate -->
    <div class="p-formgroup-inline p-col-12 p-md-9">
      <div class="p-formgroup-inline p-col-2">
        <label for="birthdate">เกิดวันที่</label>
      </div>
      <div class="p-field p-col-12 p-md-2">
        <Dropdown
          id="bdDay"
          v-model="dataBirthDay.bdDay"
          :options="bdDate.days"
          optionLabel="text"
          placeholder="เลือกวันที่"
          optionValue="value"
        />
      </div>
      <div class="p-field p-col-12 p-md-3">
        <Dropdown
          v-model="dataBirthDay.bdMonth"
          :options="bdDate.months"
          optionLabel="text"
          placeholder="เลือกเดือน"
          optionValue="value"
        />
      </div>
      <div class="p-field p-col-12 p-md-2">
        <Dropdown
          v-model="dataBirthDay.bdYear"
          :options="bdMin20YearAgo"
          optionLabel="text"
          placeholder="เลือกปี"
          optionValue="value"
        />
      </div>
    </div>
    <!-- birthdate -->
  </div>

  <!-- personnal BMI -->
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-4">
      <label for="wight">น้ำหนัก</label>
      <InputNumber
        id="WEIGHT"
        v-model="dataSet.weight"
        type="text"
        placeholder="น้ำหนัก(กก.)"
        :minFractionDigits="2"
      />
    </div>
    <div class="p-field p-col-12 p-md-4">
      <label for="height">ส่วนสูง</label>
      <InputNumber
        id="HEIGHT"
        v-model="dataSet.height"
        type="text"
        placeholder="ส่วนสูง(ซ.ม.)"
        :minFractionDigits="2"
      />
    </div>
    <div class="p-field p-col-12 p-md-4">
      <label for="arond">รอบเอว</label>
      <InputNumber
        id="WAISTLINE"
        v-model="dataSet.waistline"
        type="text"
        placeholder="รอบเอว(ซ.ม.)"
        :minFractionDigits="2"
      />
    </div>
  </div>
  <!-- / personnal BMI -->
  <!-- family -->
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-4">
      <label for="elderHouse"
        >สมาชิกในครอบครัวของผู้สูงอายุที่พักอาศัยอยู่จริงในบ้านเดียวกัน
        มีจำนวน</label
      >
      <InputNumber
        id="elderHouse"
        v-model="dataSet.memberTotal"
        type="text"
        placeholder="(คน)"
      />
    </div>
    <div class="p-field p-col-12 p-md-4">
      <label for="elderIncome"
        >สมาชิกในครอบครัวของผู้สูงอายุ ที่หารายได้เองได้ มีจำนวน</label
      >
      <InputNumber
        id="elderIncome"
        v-model="dataSet.memberEarnMoney"
        type="text"
        placeholder="(คน)"
      />
    </div>
    <div class="p-field p-col-12 p-md-4">
      <label for="elderCare"
        >สมาชิกในครอบครัวของผู้สูงอายุ ที่อยู่ในการดูแลของผู้สูงอายุ
        มีจำนวน</label
      >
      <InputNumber
        id="elderCare"
        v-model="dataSet.memberTakecare"
        type="text"
        placeholder="(คน)"
      />
    </div>
  </div>
  <!-- / family -->
</template>
<script>
import DropdownDate from "./util/DropdownDate.vue";
import * as moment from "moment";

export default {
  name: "sectionProfile",
  data() {
    return {};
  },
  computed: {
    dataBirthDay() {
      return this.$store.state.entry.birthday;
    },
    dataSet() {
      return this.$store.state.entry.entity;
    },
    bdDate() {
      return this.$store.state.constance;
    },
    bdMin20YearAgo() {
      return this.bdDate.years.filter((y, index) => index > 19);
    },
  },
  created() {
    //https://dev.to/viniciuskneves/watch-for-vuex-state-changes-2mgj
    //console.log("dataSet ::==", JSON.stringify(this.dataSet));
    /*this.$store.subscribe((mutation, state) => {
      //console.log("mutation ::=", mutation);
      //console.log("state ::=", state);
      if (mutation.type === "entry/setEntity") {
        //console.log(`Updating to ${state.status}`);
        this.dataSet = mutation.payload;
      }
    });*/
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "entry/setEntity") {
        this.$store.dispatch("entry/handlerFieldsData", mutation.payload);
      }
    });
  },
  methods: {
    calDateSelect(value) {
      //console.log("value :=", value);
      const { dateFormatGet } = this.$primevue.config.locale;
      const _value = moment(value).add(543, "year").format(dateFormatGet);
      //console.log("_value ::==", _value);
      this.$store.commit("entry/setSectionProfileBirthDate", _value);
      //this.dataSet["eBdBcStr"] = _value;
    },
  },
  components: {
    //DropdownDate: DropdownDate,
  },
};
</script>