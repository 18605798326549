<template>
  <!-- 1. ท่านคิดว่าโดยภาพรวมความสามารถในการทำงานของท่านในปัจจุบันอยู่ที่ระดับใด  -->
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-formgroup-inline p-col-12 p-sm-8">
      <label for="wight"
        >1.
        ท่านคิดว่าโดยภาพรวมความสามารถในการทำงานของท่าน<b>ในปัจจุบัน</b>อยู่ที่ระดับใด</label
      >
    </div>
    <DataTable
      :value="dataSet.decisionTableAbility"
      class="p-datatable-striped p-datatable-gridlines p-datatable-responsive"
    >
      <ColumnGroup type="header">
        <Row>
          <Column header="" :rowspan="2" />
          <Column
            header="ไม่สามารถทำงานได้เลย "
            :colspan="5"
            headerClass="p-text-left"
          />
          <Column
            header="ทำงานได้เต็มที่"
            :colspan="6"
            headerClass="p-text-right"
          />
        </Row>
        <Row>
          <Column header="0" headerClass="p-text-center" />
          <Column header="1" headerClass="p-text-center" />
          <Column header="2" headerClass="p-text-center" />
          <Column header="3" headerClass="p-text-center" />
          <Column header="4" headerClass="p-text-center" />
          <Column header="5" headerClass="p-text-center" />
          <Column header="6" headerClass="p-text-center" />
          <Column header="7" headerClass="p-text-center" />
          <Column header="8" headerClass="p-text-center" />
          <Column header="9" headerClass="p-text-center" />
          <Column header="10" headerClass="p-text-center" />
          <Column header="ปฏิเสธให้ข้อมูล" headerClass="p-text-center" />
        </Row>
      </ColumnGroup>

      <Column header="Name" field="topic" />

      <!-- option 0-->
      <Column header="ไม่สามารถทำงานได้เลย(0)" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="0"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 0-->

      <!-- option 1-->
      <Column header="1" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="1"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 1-->

      <!-- option 2-->
      <Column header="2" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="2"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 2-->

      <!-- option 3-->
      <Column header="3" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="3"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 3-->

      <!-- option  4-->
      <Column header="4" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="4"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 4-->

      <!-- option 5-->
      <Column header="5" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="5"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 5-->

      <!-- option 6-->
      <Column header="6" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="6"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 6-->

      <!-- option 7-->
      <Column header="7" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="7"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 7-->

      <!-- option 8-->
      <Column header="8" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="8"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 8-->

      <!-- option 9-->
      <Column header="9" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="9"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 9-->

      <!-- option 10-->
      <Column header="ทำงานได้เต็มที่(10)" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="10"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 10-->

      <!-- option 11-->
      <Column header="ปฏิเสธให้ข้อมูล" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="99"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 11-->
    </DataTable>
  </div>
  <!-- /1. ท่านคิดว่าโดยภาพรวมความสามารถในการทำงานของท่านในปัจจุบันอยู่ที่ระดับใด -->

  <!-- 2. หากต้องทำงานท่านคิดว่าความสามารถในการทำงานของท่านอยู่ในระดับใด  -->
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-sm-8">
      <label for="wight"
        >2.
        หากต้องทำงานท่านคิดว่า<b>ความสามารถในการทำงาน</b>ของท่านอยู่ในระดับใด
      </label>
      <div class="p-field p-col-12 p-sm-12">
        <label for="wight">1) กรณีงานที่ทำใช้<b>กำลังร่างกาย</b>เป็นหลัก</label>
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A40021"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="dataSet.workBody"
              :value="key"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>
      </div>

      <div class="p-field p-col-12 p-sm-12">
        <label for="wight"
          >2) กรณีงานที่ทำใช้<b>ความคิด</b>เป็นหลัก เช่น ทอนเงิน คำนวณตัวเลข
          เป็นต้น</label
        >
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A40022"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="dataSet.workThink"
              :value="key"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /2. หากต้องทำงานท่านคิดว่าความสามารถในการทำงานของท่านอยู่ในระดับใด  -->

  <!-- 3. ท่านคิดว่าการเจ็บป่วยที่ท่านมีอยู่กระทบความสามารถในการทำงานในระดับใด (เลือกคำตอบได้มากกว่า 1 ข้อ)  -->
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-12">
      <label for="wight"
        >3.
        ท่านคิดว่า<b>การเจ็บป่วยที่ท่านมีอยู่กระทบความสามารถในการทำงาน</b>ในระดับใด
      </label>
    </div>
    <div class="p-field p-col-12 p-md-12">
      <div class="p-field-checkbox" v-for="(value, key) in A4003" :key="key">
        <RadioButton :name="key" v-model="dataSet.workEffect" :value="key" />
        <label for="city4" v-html="value"></label>
      </div>
    </div>
  </div>
  <!-- /3. ท่านคิดว่าการเจ็บป่วยที่ท่านมีอยู่กระทบความสามารถในการทำงานในระดับใด (เลือกคำตอบได้มากกว่า 1 ข้อ) -->

  <!--4. ในช่วง 12 เดือนที่ผ่านมา ท่านหยุดทำงานหรือหยุดประกอบกิจวัตรประจำวันเนื่องจากความเจ็บป่วยกี่วัน-->
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-12">
      <label for="wight"
        >4. ในช่วง
        <b>12 เดือนที่ผ่านมา ท่านหยุดทำงานหรือหยุดประกอบกิจวัตรประจำวัน</b
        >เนื่องจากความเจ็บป่วยประมาณกี่วัน</label
      >
    </div>
    <div class="p-field p-col-12 p-md-12">
      <div class="p-field-checkbox" v-for="(value, key) in A4004" :key="key">
        <RadioButton :name="key" v-model="dataSet.workLeave" :value="key" />
        <label for="city4">{{ value }}</label>
      </div>
    </div>
  </div>
  <!-- /4. ในช่วง 12 เดือนที่ผ่านมา ท่านหยุดทำงานหรือหยุดประกอบกิจวัตรประจำวันเนื่องจากความเจ็บป่วยกี่วัน -->

  <!-- 5. ด้วยภาวะร่างกายของท่านในปัจจุบัน ท่านคิดว่าจะสามารถทำงานไปจนถึงอีก 2 ปีข้างหน้าได้หรือไม่  -->
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-12">
      <label for="wight"
        >5. ด้วยภาวะร่างกายของท่านในปัจจุบัน ท่านคิดว่าจะสามารถทำงานไปจนถึงอีก 2
        ปีข้างหน้าได้หรือไม่</label
      >
    </div>
    <div class="p-field p-col-12 p-md-12">
      <div class="p-field-checkbox" v-for="(value, key) in A4005" :key="key">
        <RadioButton :name="key" v-model="dataSet.work2yrs" :value="key" />
        <label for="city4">{{ value }}</label>
      </div>
    </div>
  </div>
  <!-- /5. ด้วยภาวะร่างกายของท่านในปัจจุบัน ท่านคิดว่าจะสามารถทำงานไปจนถึงอีก 2 ปีข้างหน้าได้หรือไม่ -->

  <!-- 6. ในการดำเนินชีวิตประจำวัน ท่านมีความรู้สึกอย่างไรต่อไปนี้  -->
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-sm-12">
      <label for="wight"
        >6. ในการดำเนินชีวิตประจำวัน ท่านมีความรู้สึกอย่างไรต่อไปนี้
      </label>
      <div class="p-field p-col-12 p-sm-12">
        <label for="wight">
          1) <b>สนุกสนาน</b>กับการทำกิจกรรมต่าง ๆ
          ในการดำเนินชีวิตประจำวัน</label
        >
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A40061"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="dataSet.feelFun"
              :value="key"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>
      </div>

      <div class="p-field p-col-12 p-sm-12">
        <label for="wight"
          >2) <b>กระตือรือร้น</b>ในการทำกิจกรรมต่าง ๆ
          ในการดำเนินชีวิตประจำวัน</label
        >
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A40062"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="dataSet.feelActive"
              :value="key"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>
      </div>

      <div class="p-field p-col-12 p-sm-12">
        <label for="wight">3) <b>มีความหวัง</b>กับชีวิตในอนาคต </label>
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A40063"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="dataSet.feelHope"
              :value="key"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /6. ในการดำเนินชีวิตประจำวัน ท่านมีความรู้สึกอย่างไรต่อไปนี้  -->

  <!-- 7. โปรดระบุการเจ็บป่วยหรือโรคที่ท่านมี (โรคที่แพทย์เป็นผู้วินิจฉัยและให้การรักษา)  -->
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-12">
      <label for="wight"
        >7. โปรดระบุการเจ็บป่วยหรือโรคประจำตัวของท่าน
        <b>(โรคที่แพทย์เป็นผู้วินิจฉัยและให้การรักษา)</b></label
      >
    </div>
    <div class="p-field p-col-12 p-md-12">
      <div class="p-field-checkbox p-col-12 p-md-12">
        <RadioButton name="marital" v-model="dataSet.disease" value="c1" />
        <label for="city4">ไม่มี</label>
      </div>
      <div class="p-field-checkbox p-col-12 p-md-12">
        <RadioButton name="marital" v-model="dataSet.disease" value="c2" />
        <label for="city4">ไม่เคยตรวจ</label>
      </div>
      <div class="p-field-checkbox p-col-12 p-md-12">
        <RadioButton name="marital" v-model="dataSet.disease" value="c3" />
        <label for="city4">มี โปรดระบุ</label>
      </div>
      <div class="p-field-checkbox p-col-12 p-md-12">
        <div class="p-field-checkbox p-col-12 p-md-12">
          <div class="p-formgroup-inline">
            <div
              class="p-field-checkbox"
              v-for="(value, key) in A40071"
              :key="key"
            >
              <Checkbox
                :name="key"
                v-model="dataSet.diseaseDetail_"
                :value="key"
                :disabled="dataSet.disease != 'c3'"
              />
              <label for="city4">{{ value }}</label>
            </div>
            <div class="p-field p-col-12 p-sm-3">
              <InputText
                type="text"
                v-model="dataSet.diseaseOth"
                placeholder="อื่น ๆ"
                :disabled="
                  isDisables('diseaseDetail_', 'c13') || dataSet.disease != 'c3'
                "
              />
            </div>
          </div>
        </div>
      </div>
      <div class="p-field-checkbox p-col-12 p-md-12">
        <RadioButton name="marital" v-model="dataSet.disease" value="c99" />
        <label for="city4">ปฏิเสธให้ข้อมูล</label>
      </div>
    </div>
  </div>
  <!-- /7. โปรดระบุการเจ็บป่วยหรือโรคที่ท่านมี (โรคที่แพทย์เป็นผู้วินิจฉัยและให้การรักษา) -->
</template>
<script>
export default {
  name: 'section4',
  data() {
    return {
      A40021: {
        c1: 'ไม่ดี',
        c2: 'ค่อนข้างไม่ดี',
        c3: 'ปานกลาง',
        c4: 'ดี',
        c5: 'ดีมาก',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A40022: {
        c1: 'ไม่ดี',
        c2: 'ค่อนข้างไม่ดี',
        c3: 'ปานกลาง',
        c4: 'ดี',
        c5: 'ดีมาก',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A4003: {
        c1: 'ทำงานได้ตามปกติ',
        c2: 'สามารถทำงานได้ตามปกติ แม้ว่างานจะทำให้มีอาการเจ็บป่วยเกิดขึ้นบ้าง',
        c3:
          'ทำงานได้น้อยลง แต่<u><b>บางครั้ง</b></u>ต้องเปลี่ยนวิธีการทำงาน เนื่องจากอาการเจ็บป่วยที่มีอยู่',
        c4:
          'ทำงานได้น้อยลง โดย<u><b>บ่อยครั้ง</b></u>ที่ต้องเปลี่ยนวิธีการทำงาน เนื่องจากอาการเจ็บป่วยที่มีอยู่',
        c5: 'ทำงานได้เฉพาะงานชั่วคราว ไม่สามารถทำงานประจำได้',
        c6: 'ไม่สามารถทำงานได้เลย',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A4004: {
        c1: 'ไม่เคยหยุดเลย',
        c2: 'หยุด 1-9 วัน',
        c3: 'หยุด 10-24 วัน',
        c4: 'หยุด 25-99 วัน',
        c5: 'หยุด 100-365 วัน',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A4005: {
        c1: 'ทำได้อย่างแน่นอน',
        c2: 'ไม่แน่ใจ',
        c3: 'ไม่น่าจะทำได้',
        c4: 'ทำไม่ได้',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A40061: {
        c1: 'ไม่เลย',
        c2: 'นาน ๆ ครั้ง',
        c3: 'บางครั้ง',
        c4: 'ค่อนข้างบ่อย',
        c5: 'เป็นประจำ',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A40062: {
        c1: 'ไม่เลย',
        c2: 'นาน ๆ ครั้ง',
        c3: 'บางครั้ง',
        c4: 'ค่อนข้างบ่อย',
        c5: 'เป็นประจำ',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A40063: {
        c1: 'ไม่เลย',
        c2: 'นาน ๆ ครั้ง',
        c3: 'บางครั้ง',
        c4: 'ค่อนข้างบ่อย',
        c5: 'เป็นประจำ',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A4007: {
        c1: 'ไม่มี',
        c2: 'ไม่เคยตรวจ',
        c3: 'มี โปรดระบุ',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A40071: {
        c1: 'ความดันโลหิตสูง',
        c2: 'เบาหวาน',
        c3: 'โรคหัวใจ',
        c4: 'ไขมันในเลือดสูง',
        c5: 'โรคหลอดเลือดสมอง',
        c6: 'ความจำเสื่อม/สมองเสื่อม',
        c7: 'โรคปอด/หอบหืด',
        c8: 'โรคเก๊าท์/ข้อเสื่อม/กระดูกเสื่อม',
        c9: 'โรคมะเร็ง',
        c10: 'โรคอ้วน',
        c11: 'มีอาการทางจิตเวช',
        c12: 'ซึมเศร้า',
        c13: 'อื่น ๆ '
      }
    }
  },
  watch: {
    'dataSet.diseaseDetail_': {
      handler(newV, oldV) {
        this.$store.dispatch('entry/handlerRefuseFields', {
          new: newV,
          old: oldV,
          name: 'diseaseDetail_'
        })
      },
      deep: true
    }
  },
  computed: {
    dataSet() {
      return this.$store.state.entry.entity
    }
  },
  methods: {
    getDataSetEntry() {
      alert(JSON.stringify(this.dataSet))
    },
    isDisables(name, value) {
      return !(this.dataSet[name] == null ? [] : this.dataSet[name]).includes(
        value
      )
    }
  }
}
</script>