<template>
  <!-- 1.สุขภาพช่องปากผู้สูงอายุ  -->
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-sm-12">
      <label for="wight"
        >1. แบบประเมินความเครียด (STS) (ในระยะ 2-4 สัปดาห์ที่ผ่านมา)
        <b>(ให้ประเมินตนเอง)</b></label
      >
    </div>
    <div class="p-field p-col-12 p-sm-12">
      <div class="p-field p-col-12 p-sm-12">
        <label for="wight">1) มีปัญหาการนอน นอนไม่หลับหรือนอนมาก</label>
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A30011"
            :key="key"
          >
            <RadioButton
              name="marital"
              :value="key"
              v-model="dataSet.stsSleep"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>
      </div>

      <div class="p-field p-col-12 p-sm-12">
        <label for="wight">2) มีสมาธิน้อยลง</label>
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A30012"
            :key="key"
          >
            <RadioButton
              name="marital"
              :value="key"
              v-model="dataSet.stsConcentration"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>
      </div>

      <div class="p-field p-col-12 p-sm-12">
        <label for="wight">3) หงุดหงิด/กระวนกระวายใจ/ว้าวุ่นใจ </label>
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A30013"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="dataSet.stsEDGY"
              :value="key"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>
      </div>

      <div class="p-field p-col-12 p-sm-12">
        <label for="wight">4) รู้สึกเบื่อ เซ็ง</label>
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A30014"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="dataSet.stsBored"
              :value="key"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>
      </div>

      <div class="p-field p-col-12 p-sm-12">
        <label for="wight">5) ไม่อยากพบปะผู้คน</label>
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A30015"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="dataSet.stsIntrovert"
              :value="key"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /1.สุขภาพช่องปากผู้สูงอายุ  -->

  <!-- 2.การประเมินความเสี่ยงในการหกล้ม -->
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-sm-8">
      <label for="wight"
        >2. การประเมินความเสี่ยงในการหกล้ม <b>(ให้ประเมินตนเอง)</b></label
      >
    </div>
    <div class="p-field p-col-12 p-sm-12">
      <div class="p-field p-col-12 p-sm-12">
        <label for="wight"
          >1) <b>ในระยะเวลา 6 เดือน</b>ที่ผ่านมา ท่านหกล้มกี่ครั้ง
          (ระบุจำนวนครั้งที่หกล้ม)
        </label>
        <div class="p-field p-col-12 p-sm-6">
          <InputNumber
            type="text"
            v-model="dataSet.fallTimes"
            placeholder="ครั้ง"
          />
        </div>
        <RadioButton
          name="fallTimesFake"
          value="fallTimesFake"
          v-model="dataSet.fallTimesFake"
        />
        <label for="wight">&nbsp;ปฏิเสธให้ข้อมูล</label>
      </div>
      <div class="p-field p-col-12 p-sm-12">
        <label for="wight"
          >2) ท่าน <b><u>ใช้</u></b> เครื่องช่วยในการเดิน เช่น ไม้เท้า ไม้ค้ำยัน
          สี่ขา จับเกาะราว หรือมีคนช่วยพยุง ในการเดินหรือไม่</label
        >
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A3002"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="dataSet.machineWalk"
              :value="key"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /2.การประเมินความเสี่ยงในการหกล้ม -->

  <!-- 3. การประเมินสมรรถภาพสมอง  (Abbreviated mental Test : AMT)  -->
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-sm-12">
      <label for="wight"
        >3. การประเมินสมรรถภาพสมอง (Thai Mental State Examination: TMSE)
        วงกลมรอบคำตอบที่ ผู้สูงอายุทำได้ (ช่องคำตอบละ 1 คะแนน: ตอบถูกได้ 1
        คะแนน/ ตอบผิดได้ 0 คะแนน)</label
      >
    </div>
    <div class="p-field p-col-12 p-sm-12">
      <label for="wight"
        >1) วันนี้วันอะไรของสัปดาห์
        <b>(จันทร์ อังคาร พุธ พฤหัสบดี ศุกร์ เสาร์ อาทิตย์)</b>
      </label>
      <div class="p-formgroup-inline">
        <div class="p-field-checkbox" v-for="(value, key) in A30031" :key="key">
          <RadioButton name="marital" v-model="dataSet.tmse1" :value="key" />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight">2) วันนี้วันที่เท่าไร</label>
      <div class="p-formgroup-inline">
        <div class="p-field-checkbox" v-for="(value, key) in A30032" :key="key">
          <RadioButton name="marital" v-model="dataSet.tmse2" :value="key" />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight">3) เดือนนี้เดือนอะไร</label>
      <div class="p-formgroup-inline">
        <div class="p-field-checkbox" v-for="(value, key) in A30033" :key="key">
          <RadioButton name="marital" v-model="dataSet.tmse3" :value="key" />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >4) ขณะนี้เป็นช่วง (ตอน) ไหนของวัน
        <b>(เช้า สาย เที่ยง บ่าย เย็น มืดค่ำ)</b></label
      >
      <div class="p-formgroup-inline">
        <div class="p-field-checkbox" v-for="(value, key) in A30034" :key="key">
          <RadioButton name="marital" v-model="dataSet.tmse4" :value="key" />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >5) ที่นี่ที่ไหน (บริเวณที่เก็บแบบสำรวจ เช่น บ้าน)</label
      >
      <div class="p-formgroup-inline">
        <div class="p-field-checkbox" v-for="(value, key) in A30035" :key="key">
          <RadioButton name="marital" v-model="dataSet.tmse5" :value="key" />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight">6) คนที่เห็นในภาพนี้มีอาชีพอะไร </label>
      <img :src="assetsNurse" alt="nurse" class="img-responsive" />
      <div class="p-formgroup-inline">
        <div class="p-field-checkbox" v-for="(value, key) in A30036" :key="key">
          <RadioButton name="marital" v-model="dataSet.tmse6" :value="key" />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >7) ผู้สำรวจบอกชื่อของ 3 อย่าง โดยพูดเว้นช่วงครั้งละ 1 วินาที
        <b>(ต้นไม้ รถยนต์ มือ)</b> เพียงครั้งเดียว แล้วจึงให้ผู้สูงอายุพูดตาม
        หากพูดได้ครบในครั้งแรก หากพูดได้ครบในครั้งแรก ถูก 1 คำ ได้ 1 คะแนน ถูก 2 คำ ได้ 2 คะแนน  ถูก 3 คำ ได้ 3 คะแนน สามารถตอบสลับคำได้  ตอบช้าได้ตามเห็นสมควร</label
      >
      <label for="wight"
        >*
        <b><u>หมายเหตุ</u></b> หลังจากให้คะแนนแล้วให้บอกซ้ำจนผู้สูงอายุจำได้ทั้ง
        3 อย่าง และบอกให้ผู้สูงอายุทราบว่าสักครู่จะกลับมาถามใหม่อีกครั้ง
      </label>
      <div class="p-formgroup-inline">
        <div class="p-field-checkbox" v-for="(value, key) in A30037" :key="key">
          <RadioButton name="marital" v-model="dataSet.tmse7" :value="key" />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >8) ให้บอกวันอาทิตย์-วันจันทร์ย้อนหลัง ให้ครบสัปดาห์ (ตอบได้ไม่เกิน 2
        ครั้ง) การให้คะแนนคำตอบ วันละ 1 คะแนน คือ วันอาทิตย์
        <b>วันเสาร์ วันศุกร์ วันพฤหัสบดี วันพุธ วันอังคาร</b> วันจันทร์</label
      >
      <div class="p-formgroup-inline">
        <div class="p-field-checkbox" v-for="(value, key) in A30038" :key="key">
          <RadioButton name="marital" v-model="dataSet.tmse8" :value="key" />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >9) ให้คำนวณตัวเลขแล้วบอกคำตอบ
        <b>100-7 ไปเรื่อย ๆ 3 ครั้ง</b> (ตอบถูกได้ 1 คะแนน)
        ใช้เวลาคิดในแต่ละช่วงคำตอบไม่เกิน 1 นาที ถ้าผู้สูงอายุไม่ตอบ คำถามที่ 1
        ให้ตั้งเลข <b>93-7</b> ในการคำนวณครั้งต่อไป และ <b>86-7</b> ในครั้ง
        สุดท้ายตามลำดับ 
        ครั้งที่ 1 : 100 – 7 (100 ลบ 7)
        ครั้งที่ 2 : 93 – 7 (93 ลบ 7)
        ครั้งที่ 3 : 86 – 7 (86 ลบ 7)
        <b>คำตอบ คือ 93, 86 และ 79 ตามลำดับ</b>)</label
      >
      <div class="p-formgroup-inline">
        <div class="p-field-checkbox" v-for="(value, key) in A30039" :key="key">
          <RadioButton name="marital" v-model="dataSet.tmse9" :value="key" />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >10)
        ผู้สำรวจชี้ไปที่<b>สิ่งของ/วัสดุ/อุปกรณ์/สิ่งมีชีวิต</b>ในบริเวณนั้น
        แล้วถาม ผู้สูงอายุว่า “<b>โดยทั่วไปเราเรียก สิ่งนี้ว่าอะไร?</b>” เช่น
        ชี้ต้นไม้ ตอบต้นไม้</label
      >
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in A300310"
          :key="key"
        >
          <RadioButton name="marital" v-model="dataSet.tmse10" :value="key" />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >11) ผู้สำรวจชี้ไปที่เสื้อของตนเอง แล้วถามผู้สูงอายุว่า “<b
          >โดยทั่วไปเราเรียกสิ่งนี้ ว่าอะไร?</b
        >”</label
      >
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in A300311"
          :key="key"
        >
          <RadioButton name="marital" v-model="dataSet.tmse11" :value="key" />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >12) ผู้สำรวจบอกผู้สูงอายุว่า จงฟังประโยคต่อไปนี้ให้ดี
        แล้วจำไว้จากนั้นให้พูดตาม “<b>ยายพาหลานไปซื้อขนมที่ตลาด</b>”</label
      >
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in A300312"
          :key="key"
        >
          <RadioButton name="marital" v-model="dataSet.tmse12" :value="key" />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >13) จงทำตามคำสั่งต่อไปนี้ (มี 3 ขั้นตอนคำสั่ง)
        ให้ผู้สำรวจ<b>พูด</b>ต่อกันไปให้ครบ ทั้ง 3 ขั้นตอน ให้คะแนนขั้นตอนละ 1
        คะแนน</label
      >
      <div class="p-field p-col-12 p-sm-8">
        <label for="wight">“<b>หยิบกระดาษด้วยมือขวา</b>”</label>
      </div>
      <div class="p-field p-col-12 p-sm-8">
        <label for="wight">“<b>พับกระดาษเป็นครึ่งแผ่น</b>”</label>
      </div>
      <div class="p-field p-col-12 p-sm-8">
        <label for="wight">“<b>แล้วส่งกระดาษให้ผู้ตรวจ</b>”</label>
      </div>
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in A300313"
          :key="key"
        >
          <RadioButton name="marital" v-model="dataSet.tmse13" :value="key" />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >14) ให้ผู้สูงอายุ<b><u>อ่านแล้วทำตาม</u></b></label
      >
      <div class="p-field p-col-12 p-sm-8">
        <label for="wight">“<b>หลับตา</b>”</label>
      </div>

      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in A300314"
          :key="key"
        >
          <RadioButton name="marital" v-model="dataSet.tmse14" :value="key" />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >15)
        จงวาดภาพต่อไปนี้ให้เหมือนตัวอย่างมากที่สุดเท่าที่ท่านจะสามารถทำได้</label
      >
      <img :src="assetsHome" alt="pic" class="img-responsive" />
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in A300315"
          :key="key"
        >
          <RadioButton name="marital" v-model="dataSet.tmse15" :value="key" />
          <label for="city4">{{ value }}</label>
        </div>
      </div>
      <label for="wight"><b>พิจารณาการให้คะแนน</b></label
      ><br />
      <label for="wight"
        >พิจารณามุมครบ (3 เหลี่ยม + 4 เหลี่ยม = 7 มุม)
        และมีด้านหนึ่งซ้อนทับกัน</label
      ><br />
      <label for="wight"
        >- วาดภาพสามเหลี่ยมและสี่เหลี่ยมเชื่อมต่อกัน<u
          >เหมือนภาพตัวอย่าง ได้คะแนน 1,1</u
        ></label
      ><br />
      <label for="wight"
        >- วาดภาพสามเหลี่ยมและสี่เหลี่ยมเชื่อมต่อกัน<u
          >คล้ายภาพตัวอย่างแต่ไม่สมบูรณ์ ได้คะแนน 1,0</u
        ></label
      ><br />
      <label for="wight"
        >- วาดภาพ<u>ไม่ใกล้เคียงภาพตัวอย่าง ได้คะแนน 0,0</u></label
      ><br />

      <label for="wight">16) กล้วยกับส้มเหมือนกัน คือ เป็นผลไม้</label>
      <div class="p-field p-col-12 p-sm-8">
        <label for="wight"><b>แมวกับสุนัขเหมือนกัน</b> คือ เป็นอะไร?</label>
      </div>
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in A300316"
          :key="key"
        >
          <RadioButton name="marital" v-model="dataSet.tmse16" :value="key" />
          <label for="city4">{{ value }}</label>
        </div>
      </div>

      <label for="wight"
        >17) สิ่งของ 3 อย่างที่บอกให้จำเมื่อสักครู่ มีอะไรบ้าง (ตอบสลับได้
        ตอบช้าได้ตาม เห็นสมควร)</label
      >
      <div class="p-field p-col-12 p-sm-8">
        <label for="wight"><b>(ต้นไม้ รถยนต์ มือ)</b></label>
      </div>
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in A300317"
          :key="key"
        >
          <RadioButton name="marital" v-model="dataSet.tmse17" :value="key" />
          <label for="city4">{{ value }}</label>
        </div>
      </div>
    </div>
  </div>

  <!-- /3. การประเมินสมรรถภาพสมอง  (Abbreviated mental Test : AMT)  -->

  <!-- 4. ในปัจจุบันท่านมีอาการปวดในบริเวณใดของร่างกาย  -->
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-formgroup-inline p-col-12 p-sm-12">
      <label for="wight"
        >4. ในปัจจุบันท่านมีอาการปวดในบริเวณใดของร่างกาย
        และมีระดับความปวดเท่าใดจากระดับ 0-10</label
      >
    </div>
    <DataTable
      :value="dataSet.decisionTablePain"
      class="p-datatable-striped p-datatable-gridlines p-datatable-responsive"
    >
      <ColumnGroup type="header">
        <Row>
          <Column header="" :rowspan="2" headerStyle="width:13%;" />
          <Column header="ไม่ปวดเลย " :colspan="5" headerClass="p-text-left" />
          <Column
            header="ปวดมากจนทนไม่ได้"
            :colspan="6"
            headerClass="p-text-right"
          />
        </Row>
        <Row>
          <Column header="0" headerClass="p-text-center" />
          <Column header="1" headerClass="p-text-center" />
          <Column header="2" headerClass="p-text-center" />
          <Column header="3" headerClass="p-text-center" />
          <Column header="4" headerClass="p-text-center" />
          <Column header="5" headerClass="p-text-center" />
          <Column header="6" headerClass="p-text-center" />
          <Column header="7" headerClass="p-text-center" />
          <Column header="8" headerClass="p-text-center" />
          <Column header="9" headerClass="p-text-center" />
          <Column header="10" headerClass="p-text-center" />
          <Column header="ปฏิเสธให้ข้อมูล" headerClass="p-text-center" />
        </Row>
      </ColumnGroup>

      <Column header="Name" field="topic" bodyStyle="white-space: nowrap; " />

      <!-- option 0-->
      <Column header="ไม่ปวดเลย(0)" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="0"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 0-->

      <!-- option 1-->
      <Column header="1" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="1"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 1-->

      <!-- option 2-->
      <Column header="2" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="2"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 2-->

      <!-- option 3-->
      <Column header="3" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="3"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 3-->

      <!-- option  4-->
      <Column header="4" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="4"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 4-->

      <!-- option 5-->
      <Column header="5" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="5"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 5-->

      <!-- option 6-->
      <Column header="6" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="6"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 6-->

      <!-- option 7-->
      <Column header="7" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="7"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 7-->

      <!-- option 8-->
      <Column header="8" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="8"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 8-->

      <!-- option 9-->
      <Column header="9" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="9"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 9-->

      <!-- option 10-->
      <Column header="ปวดมากจนทนไม่ได้(10)" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="10"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 10-->

      <!-- option 11-->
      <Column header="ปฏิเสธให้ข้อมูล" bodyClass="p-text-center">
        <template #body="slotProps">
          <RadioButton
            :name="slotProps.index"
            :value="99"
            v-model="slotProps.data['select']"
          />
        </template>
      </Column>
      <!-- /option 11-->
    </DataTable>
  </div>
  <!-- /4. ในปัจจุบันท่านมีอาการปวดในบริเวณใดของร่างกาย  -->

  <!-- 5. ในปัจจุบันท่านมีปัญหาสุขภาพต่อไปนี้หรือไม่ -->
  <label for="wight">&nbsp;</label>
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-sm-12">
      <label for="wight"
        >5. ในปัจจุบันท่านมีปัญหาสุขภาพต่อไปนี้หรือไม่
        <b>(ถ้าไม่มีอาการ/ ไม่ปัญหา/ สามารถ ให้ข้ามไปทำข้อถัดไป)</b></label
      >
      <div class="p-field p-col-12 p-sm-12">
        <label for="wight">1) มีอาการปวดคอมากจนร้าวลงแขน</label>
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A30051"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="dataSet.healthNeck"
              :value="key"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <label for="wight">2) มีอาการปวดหลังมากจนร้าวลงขา</label>
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A30052"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="dataSet.healthBack"
              :value="key"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <label for="wight"
          >3)
          มีปัญหากล้ามเนื้อหรือเส้นเอ็นที่แขนหรือขาที่ทำให้เกิดอาการปวดบ่อย</label
        >
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A30053"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="dataSet.healthMuscle"
              :value="key"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <label for="wight"
          >4) มีปัญหาข้อต่อที่ทำให้มีอาการปวดหรือเคลื่อนไหวได้ไม่เต็มที่ เช่น
          ข้อไหล่ติด ข้อเข่าเสื่อม ข้อต่อผิดรูป ยกแขนได้ไม่สุดแขน ขาโก่ง
          เป็นต้น</label
        >
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A30054"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="dataSet.healthMove"
              :value="key"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <label for="wight">5) สามารถนั่งนานเกิน 30 นาที</label>
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A30055"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="dataSet.healthSit"
              :value="key"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <label for="wight">6) สามารถยืน-เดินนานเกิน 30 นาที</label>
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A30056"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="dataSet.healthStand"
              :value="key"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <label for="wight">7) สามารถก้มหรือเอี้ยวตัวได้เต็มที่</label>
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A30057"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="dataSet.healthBow"
              :value="key"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <label for="wight"
          >8) สามารถนั่งยองๆ
          หรือมีปัญหาขณะลุกขึ้นยืนจากท่านั่งยอง</label
        >
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A30058"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="dataSet.healthSquatting"
              :value="key"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <label for="wight"
          >9) สามารถนั่งบนพื้นได้นาน เช่น นั่งคุกเข่า นั่งขัดสมาธิ
          นั่งพับเพียบหรือมีปัญหาขณะลุกขึ้นยืนจาก ท่านั่งบนพื้น</label
        >
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A30059"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="dataSet.healthKneel"
              :value="key"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <label for="wight"
          >10) สามารถยกของที่มีน้ำหนักมากกว่า 15 กิโลกรัมได้</label
        >
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A300510"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="dataSet.healthHeavy"
              :value="key"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <label for="wight"
          >11) สามารถยกของขึ้น-ลง ผลักหรือดึงของ ซ้ำๆบ่อยๆ ได้
        </label>
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A300511"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="dataSet.healthLifting"
              :value="key"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <label for="wight"
          >12) มีปัญหาในการหยิบจับ วัตถุขนาดเล็ก เช่น เหรียญ</label
        >
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A300512"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="dataSet.healthCatch"
              :value="key"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <label for="wight"
          >13) สามารถคิด หรือตัดสินใจ
          ในปัญหาที่เกิดขึ้นได้ทันที</label
        >
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A300513"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="dataSet.healthThink"
              :value="key"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <label for="wight">14) มีปัญหาการได้ยินเสียงไม่ชัดเจน</label>
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A300514"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="dataSet.healthHearing"
              :value="key"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <label for="wight"
          >15) มีอาการชา ซู่ซ่าเหมือนเป็นเหน็บ หรือแปลบปลาบคล้ายเข็มตำ
          ที่บริเวณใดบริเวณหนึ่งของแขน ขา มือ หรือ เท้า</label
        >
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A300515"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="dataSet.healthHand"
              :value="key"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>

        <label for="wight">16) มีปัญหาผิวหนังแพ้ง่าย</label>
        <div class="p-formgroup-inline">
          <div
            class="p-field-checkbox"
            v-for="(value, key) in A300516"
            :key="key"
          >
            <RadioButton
              name="marital"
              v-model="dataSet.healthSkin"
              :value="key"
            />
            <label for="city4">{{ value }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /9. ในปัจจุบันท่านมีปัญหาสุขภาพต่อไปนี้หรือไม่ -->
</template>
<script>
export default {
  name: 'section3',
  data() {
    return {
      A30011: {
        c1: 'เป็นน้อยมากหรือแทบไม่มี',
        c2: 'เป็นบางครั้ง',
        c3: 'บ่อยครั้ง',
        c4: 'เป็นประจำ',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A30012: {
        c1: 'เป็นน้อยมากหรือแทบไม่มี',
        c2: 'เป็นบางครั้ง',
        c3: 'บ่อยครั้ง',
        c4: 'เป็นประจำ',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A30013: {
        c1: 'เป็นน้อยมากหรือแทบไม่มี',
        c2: 'เป็นบางครั้ง',
        c3: 'บ่อยครั้ง',
        c4: 'เป็นประจำ',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A30014: {
        c1: 'เป็นน้อยมากหรือแทบไม่มี',
        c2: 'เป็นบางครั้ง',
        c3: 'บ่อยครั้ง',
        c4: 'เป็นประจำ',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A30015: {
        c1: 'เป็นน้อยมากหรือแทบไม่มี',
        c2: 'เป็นบางครั้ง',
        c3: 'บ่อยครั้ง',
        c4: 'เป็นประจำ',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A3002: {
        c1: 'ใช้',
        c2: 'ไม่ใช้',
        c3: 'เดินไม่ได้/ติดเตียง',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A30031: {
        0: '0 คะแนน',
        1: '1 คะแนน',
        99: 'ปฏิเสธให้ข้อมูล'
      },
      A30032: {
        0: '0 คะแนน',
        1: '1 คะแนน',
        99: 'ปฏิเสธให้ข้อมูล'
      },
      A30033: {
        0: '0 คะแนน',
        1: '1 คะแนน',
        99: 'ปฏิเสธให้ข้อมูล'
      },
      A30034: {
        0: '0 คะแนน',
        1: '1 คะแนน',
        99: 'ปฏิเสธให้ข้อมูล'
      },
      A30035: {
        0: '0 คะแนน',
        1: '1 คะแนน',
        99: 'ปฏิเสธให้ข้อมูล'
      },
      A30036: {
        0: '0 คะแนน',
        1: '1 คะแนน',
        99: 'ปฏิเสธให้ข้อมูล'
      },
      A30037: {
        0: '0 คะแนน',
        1: '1 คะแนน',
        2: '2 คะแนน',
        3: '3 คะแนน',
        99: 'ปฏิเสธให้ข้อมูล'
      },
      A30038: {
        0: '0 คะแนน',
        1: '1 คะแนน',
        2: '2 คะแนน',
        3: '3 คะแนน',
        4: '4 คะแนน',
        5: '5 คะแนน',
        6: '6 คะแนน',
        7: '7 คะแนน',
        99: 'ปฏิเสธให้ข้อมูล'
      },
      A30039: {
        0: '0 คะแนน',
        1: '1 คะแนน',
        2: '2 คะแนน',
        3: '3 คะแนน',
        99: 'ปฏิเสธให้ข้อมูล'
      },
      A300310: {
        0: '0 คะแนน',
        1: '1 คะแนน',
        99: 'ปฏิเสธให้ข้อมูล'
      },
      A300311: {
        0: '0 คะแนน',
        1: '1 คะแนน',
        99: 'ปฏิเสธให้ข้อมูล'
      },
      A300312: {
        0: '0 คะแนน',
        1: '1 คะแนน',
        99: 'ปฏิเสธให้ข้อมูล'
      },
      A300313: {
        0: '0 คะแนน',
        1: '1 คะแนน',
        2: '2 คะแนน',
        3: '3 คะแนน',
        99: 'ปฏิเสธให้ข้อมูล'
      },
      A300314: {
        0: '0 คะแนน',
        1: '1 คะแนน',
        99: 'ปฏิเสธให้ข้อมูล'
      },
      A300315: {
        0: '(0,0) คะแนน',
        1: '(1,0) คะแนน',
        2: '(1,1) คะแนน',
        99: 'ปฏิเสธให้ข้อมูล'
      },
      A300316: {
        0: '0 คะแนน',
        1: '1 คะแนน',
        99: 'ปฏิเสธให้ข้อมูล'
      },
      A300317: {
        0: '0 คะแนน',
        1: '1 คะแนน',
        2: '2 คะแนน',
        3: '3 คะแนน',
        99: 'ปฏิเสธให้ข้อมูล'
      },
      A30051: {
        c1: 'มีอาการ',
        c2: 'ไม่มีอาการ',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A30052: {
        c1: 'มีอาการ',
        c2: 'ไม่มีอาการ',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A30053: {
        c1: 'มีปัญหา',
        c2: 'ไม่มีปัญหา',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A30054: {
        c1: 'มีปัญหา',
        c2: 'ไม่มีปัญหา',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A30055: {
        c2: 'ทำได้',
        c1: 'ทำไม่ได้',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A30056: {
        c2: 'ทำได้',
        c1: 'ทำไม่ได้',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A30057: {
        c2: 'ทำได้',
        c1: 'ทำไม่ได้',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A30058: {
        c2: 'ทำได้',
        c1: 'ทำไม่ได้',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A30059: {
        c2: 'ทำได้',
        c1: 'ทำไม่ได้',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A300510: {
        c2: 'ทำได้',
        c1: 'ทำไม่ได้',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A300511: {
        c2: 'ทำได้',
        c1: 'ทำไม่ได้',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A300512: {
        c1: 'มีปัญหา',
        c2: 'ไม่มีปัญหา',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A300513: {
        c2: 'ทำได้',
        c1: 'ทำไม่ได้',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A300514: {
        c1: 'มีปัญหา',
        c2: 'ไม่มีปัญหา',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A300515: {
        c1: 'มีอาการ',
        c2: 'ไม่มีอาการ',
        c99: 'ปฏิเสธให้ข้อมูล'
      },
      A300516: {
        c1: 'มีปัญหา',
        c2: 'ไม่มีปัญหา',
        c99: 'ปฏิเสธให้ข้อมูล'
      }
    }
  },
  computed: {
    dataSet() {
      return this.$store.state.entry.entity
    },
    assetsNurse: function () {
      return require('@/assets/images/nurse.png')
    },
    assetsHome: function () {
      return require('@/assets/images/home.png')
    }
  }
}
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 768px) {
  .img-responsive {
    width: 30%;
  }
}
@media only screen and (min-width: 769px) {
  .img-responsive {
    width: 20%;
  }
}
</style>
