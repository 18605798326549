<template>
  <!-- 1.ระดับการศึกษาสูงสุด -->
  <label for="wight"><u>ข้อมูลทั่วไป</u></label>
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col">
      <label for="wight">1. ระดับการศึกษาสูงสุด</label>
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in educations"
          :key="key"
        >
          <RadioButton
            name="education"
            :value="key"
            v-model="dataSet.education"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>
    </div>
  </div>
  <!-- /1.ระดับการศึกษาสูงสุด -->

  <!-- 2. สถานภาพสมรส -->
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-sm-12">
      <label for="wight">2. สถานภาพสมรส </label>
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          style="align-items: inherit"
          v-for="(value, key) in maritalStatus"
          :key="key"
        >
          <RadioButton
            name="maritalStatus"
            :value="key"
            v-model="dataSet.maritalStatus"
          />
          <label for="city4" class="p-pt-1">{{ value }}</label>
          <div v-if="value === 'อื่นๆ'">
            <InputText
              type="text"
              placeholder="อื่น ๆ"
              v-model="dataSet.maritalStatusOth"
              :disabled="isDisables('maritalStatus', 'c5')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /2. สถานภาพสมรส -->

  <!-- 3. สิทธิการรักษาพยาบาล  -->
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-12">
      <label for="wight">3. สิทธิการรักษาพยาบาล</label>
    </div>
    <div class="p-formgroup-inline p-col-12 p-md-12">
      <div
        class="p-field-checkbox"
        style="align-items: inherit"
        v-for="(value, key) in medicalRight"
        :key="key"
      >
        <Checkbox v-model="dataSet.medical_" :name="key" :value="key" />
        <label for="city4" class="p-pt-1">{{ value }}</label>
        <div v-if="value === 'อื่นๆ ระบุ'">
          <InputText
            type="text"
            v-model="dataSet.medicalOth"
            placeholder="อื่น ๆ"
            :disabled="isDisables('medical_', 'c8')"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- /3. สิทธิการรักษาพยาบาล -->

  <!-- 4. สภาพบ้านที่อยู่อาศัย -->
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-sm-12">
      <label for="wight">4. สภาพบ้านที่อยู่อาศัย </label>
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in statusHouse"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="dataSet.residential"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>
    </div>
  </div>
  <!-- /4. สภาพบ้านที่อยู่อาศัย -->

  <!-- 5. อาศัยอยู่กับใครในบ้าน -->
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-sm-12">
      <label for="wight">5. อาศัยอยู่กับใครในบ้าน </label>
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in livesWith"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="dataSet.houseLive"
            :value="key"
          />
          <label for="city4">{{ value }}</label>
        </div>
      </div>
    </div>
  </div>
  <!-- /5. อาศัยอยู่กับใครในบ้าน -->

  <!-- 6. ที่อยู่อาศัยเป็นของ -->
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-sm-12">
      <label for="wight">6. ที่อยู่อาศัยเป็นของ </label>
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          style="align-items: inherit"
          v-for="(value, key) in houseOwner"
          :key="key"
        >
          <RadioButton
            name="marital"
            v-model="dataSet.houseBelong"
            :value="key"
          />
          <label for="city4" class="p-pt-1">{{ value }}</label>
          <div v-if="value === 'อื่นๆ ระบุ'">
            <InputText
              type="text"
              v-model="dataSet.houseBelongOth"
              placeholder="อื่น ๆ"
              :disabled="isDisables('houseBelong', 'c4')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /6. ที่อยู่อาศัยเป็นของ -->

  <!-- 7. ลักษณะห้องนอน -->
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-sm-12">
      <label for="wight">7. ลักษณะห้องนอน </label>
      <div class="p-formgroup-inline">
        <div
          class="p-field-checkbox"
          v-for="(value, key) in bedroomStyle"
          :key="key"
        >
          <RadioButton name="marital" v-model="dataSet.bedroom" :value="key" />
          <label for="city4">{{ value }}</label>
        </div>
      </div>
    </div>
  </div>
  <!-- /7. ลักษณะห้องนอน -->

  <!-- 8. ลักษณะห้องส้วม  -->
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-12">
      <label for="wight">8. ลักษณะห้องส้วม</label>
    </div>
    <div class="p-formgroup-inline p-col-12 p-md-12">
      <div
        class="p-field-checkbox"
        v-for="(value, key) in toiletStyle"
        :key="key"
      >
        <Checkbox :name="key" v-model="dataSet.restroom_" :value="key" />
        <label for="city4">{{ value }}</label>
      </div>
    </div>
  </div>
  <!-- /8. ลักษณะห้องส้วม -->

  <!-- 9. สามารถติดต่อสื่อสาร โดยมือถือสมาร์ทโฟนได้  เช่น ไลน์ เฟสบุ๊ค  -->
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-12">
      <label for="wight"
        >9. สามารถใช้โทรศัพท์มือถือสมาร์ทโฟนในการติดต่อสื่อสารออนไลน์ได้ เช่น ไลน์ เฟสบุ๊ค ยูทูป ธุรกรรมทางการเงิน ถุงเงิน คนละครึ่ง เป๋าตัง เรารักกัน เราชนะ หมอพร้อม ฯลฯ </label
      >
    </div>
    <div class="p-field p-col-12 p-md-12">
      <label for="wight"
        ><b
          >(โทรศัพท์มือถือสมาร์ทโฟน คือ
          โทรศัพท์มือถือที่สามารถใช้งานอินเตอร์เน็ตได้)</b
        ></label
      >
    </div>
    <div class="p-formgroup-inline p-col-12 p-md-12">
      <div
        class="p-field-checkbox"
        v-for="(value, key) in connection"
        :key="key"
      >
        <Checkbox :name="key" v-model="dataSet.useSmartphone_" :value="key" />
        <label for="city4">{{ value }}</label>
      </div>
    </div>
  </div>
  <!-- /9. สามารถติดต่อสื่อสาร โดยมือถือสมาร์ทโฟนได้  เช่น ไลน์ เฟสบุ๊ค -->

  <!-- 10. ใน 12 เดือนที่ผ่านมา ท่านได้รับการดูแลช่วยเหลือจากหน่วยงานท้องถิ่นในด้านใดบ้าง  -->
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-12">
      <label for="wight"
        >10. ใน 12 เดือนที่ผ่านมา
        ท่านได้รับการดูแลช่วยเหลือจาก<b>หน่วยงานท้องถิ่น</b>ในด้านใดบ้าง</label
      >
    </div>

    <div class="p-field p-col-12 p-md-12">
      <div class="p-field-checkbox" v-for="(value, key) in getHelp" :key="key">
        <Checkbox :name="key" v-model="dataSet.helpOrg_" :value="key" />
        <label for="city4">{{ value }}</label>
        <div
          v-if="value === 'ได้รับการช่วยเหลือด้านการพัฒนาทักษะทางอาชีพ เช่น '"
        >
          <div class="p-field p-col-12 p-md-12">
            <InputText
              type="text"
              v-model="dataSet.helpSkill"
              placeholder=""
              :disabled="isDisables('helpOrg_', 'c1')"
            />
          </div>
        </div>
        <div v-if="value === 'ได้รับการช่วยเหลืออุปกรณ์การแพทย์ เช่น '">
          <div class="p-field p-col-12 p-md-12">
            <InputText
              type="text"
              v-model="dataSet.helpMedical"
              placeholder=""
              :disabled="isDisables('helpOrg_', 'c2')"
            />
          </div>
        </div>
        <div v-if="value === 'อื่นๆ ระบุ'">
          <div class="p-field p-col-12 p-md-12">
            <InputText
              type="text"
              v-model="dataSet.helpOrgOth"
              placeholder="อื่น ๆ"
              :disabled="isDisables('helpOrg_', 'c8')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /10. ใน 12 เดือนที่ผ่านมา ท่านได้รับการดูแลช่วยเหลือจากหน่วยงานท้องถิ่นในด้านใดบ้าง -->
</template>

<script>
export default {
  name: "section1",
  data() {
    return {
      educations: {
        c1: "ไม่ได้รับการศึกษา",
        c2: "ประถมศึกษา",
        c3: "มัธยมศึกษา/ปวช./ปวส.",
        c4: "ปริญญาตรี",
        c5: "สูงกว่าปริญญาตรี",
        c99: "ปฏิเสธให้ข้อมูล",
      },
      maritalStatus: {
        c1: "โสด",
        c2: "สมรส",
        c3: "หม้าย",
        c4: "หย่า/แยก",
        c5: "อื่นๆ",
        c99: "ปฏิเสธให้ข้อมูล",
      },
      medicalRight: {
        c1: "บัตรทองผู้สูงอายุ",
        c2: "เบิกจ่ายตรง (ข้าราชการ)",
        c3: "ประกันชีวิต/ประกันสุขภาพ",
        c4: "จ่ายเอง",
        c5: "บัตรทองผู้พิการ",
        c6: "สวัสดิการพนักงาน (เอกชน)",
        c7: "ประกันสังคม",
        c8: "อื่นๆ ระบุ",
        c99: "ปฏิเสธให้ข้อมูล",
      },
      statusHouse: {
        c1: "บ้านเป็นหลังมีสภาพดี",
        c2: "เพิงพักอาศัย",
        c3: "สภาพบ้านจำเป็นต้องซ่อมแซม",
        c99: "ปฏิเสธให้ข้อมูล",
      },
      livesWith: {
        c1: "อาศัยอยู่คนเดียว",
        c2: "คู่สมรส/คู่ครอง",
        c3: "อาศัยอยู่กับครอบครัว",
        c4: "ญาติ",
        c99: "ปฏิเสธให้ข้อมูล",
      },
      houseOwner: {
        c1: "บ้านตนเอง",
        c2: "ญาติพี่น้อง",
        c3: "บ้านเช่า",
        c4: "อื่นๆ ระบุ",
        c99: "ปฏิเสธให้ข้อมูล",
      },
      bedroomStyle: {
        c1: "นอนชั้นบนของบ้าน",
        c2: "นอนชั้นล่าง",
        c99: "ปฏิเสธให้ข้อมูล",
      },
      toiletStyle: {
        c1: "โถส้วมนั่งยอง (แบบตักน้ำราด)",
        c2: "โถนั่งราบ (ชักโครก)",
        c3: "มีราวจับ",
        c4: "ไม่มีราวจับ",
        c99: "ปฏิเสธให้ข้อมูล",
      },
      connection: {
        c1: "สื่อสารออนไลน์ได้",
        c2: "สื่อสารออนไลน์ไม่ได้",
        c3: "ไม่มีโทรศัพท์มือถือสมาร์ทโฟน",
        c4: "ไม่มีโทรศัพท์มือถือ",
        c99: "ปฏิเสธให้ข้อมูล",
      },
      getHelp: {
        c1: "ได้รับการช่วยเหลือด้านการพัฒนาทักษะทางอาชีพ เช่น ",
        c2: "ได้รับการช่วยเหลืออุปกรณ์การแพทย์ เช่น ",
        c3: "ได้รับการช่วยเหลือค่าเดินทางไปโรงพยาบาล",
        c4: "ได้รับการช่วยเหลือรถรับ-ส่งไปโรงพยาบาล",
        c5:
          "ได้รับการช่วยเหลือเบี้ยยังชีพผู้สูงอายุ เบี้ยยังชีพคนพิการ",
        c6:
          "ได้รับการช่วยเหลือเงินผู้ด้อยโอกาส ผู้ประสบปัญหาสังคม เงินช่วยเหลือครอบครัว เงินช่วยเหลือเด็กในครอบครัว เงินช่วยเหลือคนพิการ ",

        c7: "ซ่อมแซม/ปรับสภาพบ้าน และห้องน้ำ",
        c8: "อื่นๆ ระบุ",
        c99: "ปฏิเสธให้ข้อมูล",
      },
    };
  },
  watch: {
    "dataSet.medical_": {
      handler(newV, oldV) {
        this.$store.dispatch("entry/handlerRefuseFields", {
          new: newV,
          old: oldV,
          name: "medical_",
        });
      },
      deep: true,
    },
    "dataSet.restroom_": {
      handler(newV, oldV) {
        this.$store.dispatch("entry/handlerRefuseFields", {
          new: newV,
          old: oldV,
          name: "restroom_",
        });
      },
      deep: true,
    },
    "dataSet.useSmartphone_": {
      handler(newV, oldV) {
        this.$store.dispatch("entry/handlerRefuseFields", {
          new: newV,
          old: oldV,
          name: "useSmartphone_",
        });
      },
      deep: true,
    },
    "dataSet.helpOrg_": {
      handler(newV, oldV) {
        this.$store.dispatch("entry/handlerRefuseFields", {
          new: newV,
          old: oldV,
          name: "helpOrg_",
        });
      },
      deep: true,
    },
  },
  created() {
    const unsubscribe = this.$store.subscribeAction((action, state) => {
      //console.log(action.type);
      //console.log(action.payload);
      if (action.type === "entry/handlerEntries" && action.payload) {
        console.log(`section Profile :: Updating to ${state.status}`);
        this.dataSet = action.payload;
        unsubscribe();
      }
    });
  },
  computed: {
    dataSet() {
      return this.$store.state.entry.entity;
    },
  },
  mounted() {
    const { medical } = this.$store.state.entry.entity;
    this.dataSet = {
      ...this.$store.state.entry.entity,
      medical: medical == null ? [] : medical,
    };
  },
  methods: {
    isDisables(name, value) {
      return !(this.dataSet[name] == null ? [] : this.dataSet[name]).includes(
        value
      );
    },
  },
};
</script>